var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content-devx"},[_c('DxForm',{attrs:{"id":"orderForm"}},[_c('DxGroupItem',{attrs:{"css-class":"content-combo","col-count":2}},[_c('DxSimpleItem',{attrs:{"name":"client","data-field":_vm.$t('newOrder.newOrder_client'),"editor-options":{
          value: _vm.newOrder.client,
          onValueChanged: _vm.onChangeClient,
        }}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('newOrder.newUser.DxCostForm_Id_required')}})],1),_vm._v(" "),_c('DxSimpleItem',{attrs:{"name":"order","data-field":_vm.$t('newOrder.newOrder_order'),"editor-options":{
          value: _vm.newOrder.order,
          onValueChanged: _vm.onChangeOrder,
        }}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('newOrder.newUser.DxCostForm_Id_required')}})],1),_vm._v(" "),_c('DxSimpleItem',{attrs:{"name":"issued","data-field":_vm.$t('newOrder.newOrder_emit'),"editor-options":{
          value: _vm.newOrder.issued,
          onValueChanged: _vm.onChangeIssued,
        }}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('newOrder.newUser.DxCostForm_Id_required')}})],1),_vm._v(" "),_c('DxSimpleItem',{attrs:{"name":"delivery","data-field":_vm.$t('newOrder.newOrder_delivery'),"editor-options":{
          value: _vm.newOrder.delivery,
          onValueChanged: _vm.onChangeDelivery,
        }}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('newOrder.newUser.DxCostForm_price_required')}})],1),_vm._v(" "),_c('DxSimpleItem',{attrs:{"name":"email","data-field":_vm.$t('newOrder.newOrder_address'),"col-span":2,"editor-options":{
          value: _vm.newOrder.email,
          onValueChanged: _vm.onChangeEmail,
        }}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('newOrder.newUser.DxCostForm_Id_required')}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }