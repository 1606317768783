<!-- EDITAR -->
<template>
  <div class="content-devx">
    <DxForm
      class="padding-half"
    >
      <DxSimpleItem
        name="name"
        data-field="name"
        :editor-options="{
          onValueChanged: onChangeName,
          value: currentUser.name,
        }"
      >
        <DxLabel :text="$t('NOMBRE')" />
        <DxRequiredRule :message="$t('Field_Required')" />
      </DxSimpleItem>

      <DxSimpleItem
        v-if="user.contact.staff"
        name="company_name"
        data-field="company_name"
        editor-type="dxSelectBox"
        :editor-options="{
          items: companies,
          displayExpr: 'value',
          valueExpr: 'id',
          onValueChanged: onChangeCompany,
          value:currentUser.company_name,
          placeholder: currentUser.company_name,
        }"
      >
        <DxLabel :text="$t('Compañia')" />
        <DxRequiredRule :message="$t('Field_Required')" />
      </DxSimpleItem>

      <DxSimpleItem
        name="surname"
        data-field="surname"
        :editor-options="{
          onValueChanged: onChangeSurname,
          value: currentUser.surname,
        }"
      >
        <DxLabel :text="$t('APELLIDO')" />
        <DxRequiredRule :message="$t('Field_Required')" />
      </DxSimpleItem>
      <DxSimpleItem

        data-field="email"
        :editor-options="{
          onValueChanged: onChangeEmail,
          value: currentUser.email,
        }"
      >
        <DxLabel :text="$t('EMAIL')" />
        <DxRequiredRule :message="$t('Field_Required')" />
      </DxSimpleItem>
      <DxSimpleItem
        name="password"
        data-field="password"
        :editor-options="{
          onValueChanged: onChangePassword,
          placeholder: $t('Indique su contraseña ...'),
        }"
      >
        <DxLabel :text="$t('CONTRASEÑA')" />
      </DxSimpleItem>

      <DxSimpleItem
        name="confirmPassword"
        data-field="confirmPassword"
        :editor-options="{
          onValueChanged: onChangeConfirmPassword,
          placeholder: $t('Repita su contraseña ...'),
        }"
      >
        <DxLabel :text="$t('CONFIRMAR')" />
      </DxSimpleItem>
    </DxForm>
    <div>
      <label
        class="margin dx-field-item-label dx-field-item-label-location-left"
        for="dx_dx-6598e3e6-1fec-d875-6768-c9bd57af04a5_password"
      >
        <span
          class="dx-field-item-label-content"
          style="width: 92px;"
        >
          <span class="dx-field-item-label-text">Hacer usuario Activo</span>
        </span>
      </label>
      <DxCheckBox
        :value="currentUser.status"
        :text="$t('ACTIVO').toUpperCase()"
        class="padding"
        @value-changed="OnSelectedMakeActive"
      />
    </div>
    <div v-if="user.contact.staff">
      <label
        class="margin dx-field-item-label dx-field-item-label-location-left"
        for="dx_dx-6598e3e6-1fec-d875-6768-c9bd57af04a5_password"
      >
        <span
          class="dx-field-item-label-content"
          style="width: 92px;"
        >
          <span class="dx-field-item-label-text">Hacer usuario STAFF :</span>
        </span>
      </label>
      <DxCheckBox
        :value="currentUser.staff"
        :text="$t('STAFF').toUpperCase()"
        class="padding"
        @value-changed="OnSelectedMakeStaff"
      />
    </div>
    <div v-if="user.contact.admin || user.contact.staff">
      <label
        class="margin dx-field-item-label dx-field-item-label-location-left"
        for="dx_dx-6598e3e6-1fec-d875-6768-c9bd57af04a5_password"
      >
        <span
          class="dx-field-item-label-content"
          style="width: 92px;"
        >
          <span class="dx-field-item-label-text">Hacer usuario ADMN:</span>
        </span>
      </label>
      <DxCheckBox
        :value="currentUser.admin"
        :text="$t('ADMIN').toUpperCase()"
        class="padding"
        @value-changed="OnSelectedMakeAdmin"
      />
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import {
  DxForm,
  DxSimpleItem,
  DxRequiredRule,
  DxLabel,

} from 'devextreme-vue/form';
import DxCheckBox from 'devextreme-vue/check-box';

export default {
  name: 'EditUser',
  components: {
    DxForm,
    DxSimpleItem,
    DxRequiredRule,
    DxLabel,
    DxCheckBox,

  },
  props: {

    editForm: { type: Boolean, default: false },
    // user: { type: Object, default: () => {} },
    contact: { type: Object, default: () => {} },
  },
  data() {
    return {
      admin: false,
      staff: false,
      status: false,
    };
  },
  beforeMount() {
    this.fetchCompanies();
  },
  computed: {
    ...mapState('authentication', ['user']),
    ...mapState('UserData', ['currentUser', 'currentCompany', 'companies']),
  },
  methods: {

    onChangeName(e) {
      if (e.event) {
        this.updateCurrentUserProperty({ property: 'name', newValue: e.value });
      }
    },
    onChangeSurname(e) {
      if (e.event) {
        this.updateCurrentUserProperty({
          property: 'surname',
          newValue: e.value,
        });
      }
    },
    onChangeEmail(e) {
      if (e.event) {
        this.updateCurrentUserProperty({
          property: 'email',
          newValue: e.value,
        });
      }
    },
    onChangeUsername(e) {
      if (e.event) {
        this.updateCurrentUserProperty({
          property: 'username',
          newValue: e.value,
        });
      }
    },
    onChangeCompany(e) {
      if (e.event) {
        this.updateCurrentUserProperty({
          property: 'company_id',
          newValue: e.value,
        });
      }
    },
    onChangePassword(e) {
      if (e.event) {
        this.updateCurrentUserProperty({
          property: 'password',
          newValue: e.value,
        });
      }
    },
    onChangeConfirmPassword(e) {
      if (e.event) {
        this.updateCurrentUserProperty({
          property: 'confirmPassword',
          newValue: e.value,
        });
      }
    },
    OnSelectedMakeStaff(e) {
      this.updateCurrentUserProperty({
        property: 'staff',
        newValue: e.value,
      });
    },
    OnSelectedMakeAdmin(e) {
      this.updateCurrentUserProperty({
        property: 'admin',
        newValue: e.value,
      });
    },

    OnSelectedMakeActive(e) {
      this.updateCurrentUserProperty({
        property: 'status',
        newValue: e.value,
      });
    },

    ...mapActions('UserData', ['updateCurrentUserProperty', 'setCurrentUser', 'fetchCompanies']),
  },
};
</script>

<style lang="scss" scoped>
@import "./EditUser.styles.scss";
</style>
