import Api from '../../services/Api';
import Helpers from '../../js/helpers';

export default {
  namespaced: true,
  state: {
    newUser: {},
    confirmEmail: '',
    confirmPassword: '',
    isNewUser: false,
  },
  actions: {
    setName(context, value) {
      context.commit('setName', value);
    },
    setProvince(context, value) {
      context.commit('setProvince', value);
    },
    setTypeInscription(context, value) {
      context.commit('setTypeInscription', value);
    },
    setEmail(context, value) {
      context.commit('setEmail', value);
    },
    setPassword(context, value) {
      context.commit('setPassword', value);
    },
    setSurname(context, value) {
      context.commit('setSurname', value);
    },
    setConfirmPassword(context, value) {
      context.commit('setConfirmPassword', value);
    },
    setCompany(context, value) {
      context.commit('setCompany', value);
    },
    resetNewUser(context, value) {
      context.commit('resetNewUser', value);
    },
    setConfirmEmail(context, value) {
      context.commit('setConfirmEmail', value);
    },
    async fetchProfile({ state, commit }) {
      try {
        const xhr = await Api.fetchProfile();
        const dataProfile = JSON.parse(xhr.response);
        commit('fillDataUser', dataProfile);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
    async updateProfile({ state, commit }, data) {
      try {
        const xhr = await Api.updateProfile(data);
        const profile = JSON.parse(xhr.response);
        return Promise.resolve(profile);
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
  },
  mutations: {
    setIsNewUser(state, value) {
      state.isNewUser = value;
    },
    setName(state, value) {
      state.newUser.name = value;
    },
    setProvince(state, value) {
      state.newUser.province = value;
    },
    setTypeInscription(state, value) {
      state.newUser.typeInscription = value;
    },
    setEmail(state, value) {
      state.newUser.email = value;
    },
    setPassword(state, value) {
      state.newUser.password = value;
    },
    setSurname(state, value) {
      state.newUser.surname = value;
    },
    setConfirmPassword(state, value) {
      state.confirmPassword = value;
    },
    setConfirmEmail(state, value) {
      state.confirmEmail = value;
    },
    fillDataUser(state, value) {
      state.newUser = value;
    },
    setCompany(state, value) {
      state.newUser.company_name = value;
    },
    resetNewUser(state, value) {
      state.newUser = {};
    },
  },
};
