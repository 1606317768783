<template>
  <div>
    <f7-block>
      <div class="text-align-center">
        <img
          class="img_logo"
          :src="APP_LOGO"
          :alt="APPLICATION_NAME"
        >
        <!-- <span class="titleLogin"> {{ 'VegSyst-DSS' }} </span> -->
      </div>

      <div
        v-if="!isNewUser"
        class="content-devx"
      >
        <f7-list
          form
          no-hairlines-between
        >
          <div class="content-menu-header">
            <div class="menu-header-item">
              <span class="version-app">1.0.0</span>
              <img
                class="image_logo"
              >
              <!--   :src="loadLogo" -->
            </div>
          </div>
          <f7-list-item
            name="txtWelcome"
            class="login label-text"
          >
            {{ $t("Login_txtWelcome") }}
          </f7-list-item>
          <!-- Username -->
          <f7-list-input
            name="inpUsername"
            outline
            item-label
            type="text"
            :placeholder="$t('Login_inpUsername_placeholder')"
            clear-button
            max="255"
            required
            validate
            :error-message="$t('Login_inpUsername_validate')"
            @input="username = $event.target.value"
          />
          <!-- Password -->
          <f7-list-input
            name="inpPassword"
            outline
            item-label
            type="password"
            :placeholder="$t('Login_inpPassword_placeholder')"
            clear-button
            max="255"
            required
            autocomplete="current-password"
            validate
            :error-message="$t('Login_inpPassword_validate')"
            @input="password = $event.target.value"
            @keypress.native.enter="signIn"
          />
        </f7-list>

        <!-- Buttons -->
        <f7-list>
          <!-- <f7-list-item
            checkbox
            value="chkRememberMe"
            name="chkRememberMe"
            :title="$t('Login_chkRememberMe')"
            :checked="wantToRemember"
            @change="setWantToRemember"
          /> -->

          <f7-button
            name="btnSignIn"
            class="botonPrimary"
            :disabled="btnDisable"
            style="color:#FFFFFF"
            @click="signIn"
          >
            {{ $t("Login_btnSignIn") }}
          </f7-button>
        </f7-list>
        <div
          v-if="hasSignUp"
          name="btnSignUp"
          @click="signUp"
        >
          {{ $t("Login_btnSignUp_question") }}
          <strong>{{ $t("Login_btnSignUp_link") }}</strong>
        </div>
      </div>
    </f7-block>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import {
  APPLICATION_NAME, APP_LOGO,
} from '../../js/constants';
import cordovaApp from '../../js/cordova-app';
import Api from '../../services/Api';
import EventBus from '../../js/event-bus';

export default {
  name: 'Login',
  components: {
  },
  props: {
    hasRestorePass: { type: Boolean, default: false },
    hasSignUp: { type: Boolean, default: false },
    hasRememberMe: { type: Boolean, default: false },
    hasNeedHelp: { type: Boolean, default: false },
  },
  data() {
    return {
      username: '',
      password: '',
      APPLICATION_NAME,
      APP_LOGO,

    };
  },
  computed: {
    btnDisable() {
      return this.username && !this.password;
    },
    appLogo() {
      return localStorage.getItem('applogo') || this.APP_LOGO;
    },
    ...mapState('authentication', ['isLogged', 'wantToRemember']),
    ...mapState('User', ['isNewUser']),
  },
  beforeMount() {
    this.resetLastSync();
    EventBus.$on('azure_callback_auth0', this.updateAuthAzure);
  },
  methods: {
    async signIn() {
      this.$f7.preloader.show();
      try {
        await Api.login({
          username: this.username,
          password: this.password,
        });
        this.$f7.views.main.router.navigate('/dashboard/', { reloadCurrent: true });
      } catch (reason) {
        const message = this.$helpers.getFilteredErrorMessage(reason);
        if (message.includes('incorrect authentication')) {
          this.$f7.dialog.alert(this.$t(message));
        }
        if (message.includes('default_') && !message.includes('404')) {
          this.$f7.dialog.alert(message.split('_').pop());
        } else if (!message.includes('404')) {
          this.$f7.dialog.alert(this.$t(message));
        }
      } finally {
        this.$f7.preloader.hide();
      }
    },

    async signAzure() {
      this.$f7.preloader.show();
      try {
        const xhr = await Api.AzureAuthorization();
        const data = JSON.parse(xhr.response);
        cordovaApp.openBrowser(data.redirect_url);
      } catch (error) {
        console.error(error);
        this.$f7.dialog.alert(this.$helpers.getError(error));
      } finally {
        this.$f7.preloader.hide();
      }
    },
    async updateAuthAzure(authCode) {
      this.$f7.preloader.show();
      window.history.replaceState({}, document.title, '/');
      this.setWantToRemember(true);
      try {
        await Api.AzureGetToken(authCode);
      } catch (error) {
        console.error(error);
        this.$f7.dialog.alert(this.$helpers.getError(error));
      } finally {
        this.$f7.preloader.hide();
      }
    },
    signUp() {
      const self = this;
      const router = self.$f7router;
      router.navigate('/recoveryPasswordPage/', { reloadCurrent: true });
    },
    quit() {
      const self = this;
      const router = self.$f7router;
      router.back();
    },
    newUser() {
      this.setIsNewUser(true);
    },
    ...mapActions('authentication', ['login']),
    ...mapActions('User', ['setIsNewUser']),
    ...mapActions('databaseSync', ['resetLastSync']),
    ...mapMutations('authentication', ['setWantToRemember', 'wantToRecoveryPass', 'changePassword']),
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/styles/accounts/login.scss";

</style>
