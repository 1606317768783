<template>
  <f7-page>
    <navbar :text="`${$t('LeftPanel_subtitle_user')}`" />
    <div
      v-if="loaded"
    >
      <EditUserForm
        v-if="loaded"
        :current-user="currentUser"
      />
      <f7-block-footer
        class="content-action-botton"
      >
        <div
          style="justify-content: flex-start; float: left;"
          class="dx-item dx-toolbar-item dx-toolbar-button"
          @click="goToUsers()"
        >
          <div
            class="dx-item-content dx-toolbar-item-content"
          >
            <div
              class="dx-button dx-button-normal dx-button-mode-contained
              dx-widget add-task dx-button-has-icon dx-button-has-text"
              style=" background: #ffffff;
              border-color: var(--adp-gray);
              color: #838383;"
              aria-label="FICHERO"
              tabindex="0"
              role="button"
            >
              <div
                class="dx-button-content"
                style="
                  display: flex; align-items: center; justify-content:
                  center; height: 34px; min-width: 100px; overflow: hidden;
                  position: relative; margin-right: 6px !important; margin-left: 6px !important;
                  border-radius: 3px !important;
                 "
              >
                <span class="dx-button-text">CANCELAR</span>
              </div>
            </div>
          </div>
        </div>

        <div
          style="justify-content: flex-start; float: left;"
          class="dx-item dx-toolbar-item dx-toolbar-button"
          @click="editEmployee()"
        >
          <div class="dx-item-content dx-toolbar-item-content">
            <div
              class="dx-button dx-button-normal dx-button-mode-contained
          dx-widget add-task dx-button-has-icon dx-button-has-text"
              aria-label="FICHERO"
              tabindex="0"
              role="button"
            >
              <div
                class="dx-button-content"
                style="display: flex; align-items: center;
                justify-content: center; height: 34px; min-width: 100px;
                overflow: hidden; position: relative; margin-right: 6px !important;
                margin-left: 6px !important; border-radius: 3px !important;"
              >
                <span class="dx-button-text">GUARDAR</span>
              </div>
            </div>
          </div>
        </div>
      </f7-block-footer>
    </div>
  </f7-page>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import navbar from '../../components/navbar/NavBar.vue';
import EditUserForm from '../../components/users/editUserForm/index.vue';
import Api from '../../services/Api';

export default {
  name: 'EditUser',
  components: {
    EditUserForm,
    navbar,
  },
  data() {
    return {
      loaded: false,
      somerror: false,
    };
  },
  computed: {
    ...mapState('UserData', ['usersErp', 'currentUser', 'companiesErp',
      'customersAvailables', 'providersAvailables', 'isCompanyOcph']),
  },
  async beforeMount() {
    this.$f7.preloader.show();
    if (this.employee === null) {
      this.title = this.$t('CreateEmployee_Title');
    } else {
      this.title = this.$t('EditEmployee_Title');
    }
    try {
      console.log();
    } catch (error) {
      console.error(error);
      this.$f7.dialog.alert(this.$t(`${error}`));
    } finally {
      this.loaded = true;
      this.$f7.preloader.hide();
    }
  },
  methods: {
    goToUsers() {
      this.$f7.views.main.router.navigate('/users/', { reloadCurrent: true });
    },
    async editEmployee() {
      if (this.isValidUserForm()) {
        this.$f7.preloader.show();
        try {
          this.somerror = false;
          const postNewUser = {};
          postNewUser.name = this.currentUser.name;
          postNewUser.surname = this.currentUser.surname;
          postNewUser.email = this.currentUser.email;
          postNewUser.username = this.currentUser.username;
          postNewUser.company_id = this.currentUser.company_id;
          postNewUser.staff = this.currentUser.staff;
          postNewUser.admin = this.currentUser.admin;
          postNewUser.status = this.currentUser.status;
          if (this.currentUser.password !== 'undefined') postNewUser.password = this.currentUser.password;
          // postNewUser.ocph = this.currentUser.isUserOcph;
          // postNewUser.erp_provider_id = this.currentUser.companyProvider;
          // postNewUser.erp_customer_id = this.currentUser.companyCustomer;
          await Api.editUser(postNewUser, this.currentUser.id);
        } catch (error) {
          console.error(error);
          this.somerror = true;
          this.$f7.dialog.alert(this.$t(`${error}`));
        } finally {
          this.$f7.preloader.hide();
          if (!this.somerror) {
            this.$f7.dialog.alert(this.$t('Usuario Modificado con exito'));
          }
          this.somerror = false;
        }
      }
    },
    isValidUserForm() {
      let isValidForm = true;
      if (typeof this.currentUser.erp_id === 'undefined') {
        if (typeof this.currentUser.company_id === 'undefined') {
          this.$f7.dialog.alert(this.$t('Configuration.AddUserForm.companyUserForm'));
          isValidForm = false;
        } else if (typeof this.currentUser.name === 'undefined'
        || this.currentUser.name === '') {
          this.$f7.dialog.alert(this.$t('Configuration.AddUserForm.nameValidation'));
          isValidForm = false;
        } else if (this.currentUser.surname === ''
        || typeof this.currentUser.surname === 'undefined') {
          this.$f7.dialog.alert(this.$t('Configuration.AddUserForm.surnameValidation'));
          isValidForm = false;
        } else if (this.currentUser.email === ''
        || typeof this.currentUser.email === 'undefined') {
          this.$f7.dialog.alert(this.$t('Configuration.AddUserForm.emailValidation'));
          isValidForm = false;
        } else if (this.currentUser.password !== this.currentUser.confirmPassword) {
          this.$f7.dialog.alert(this.$t('Configuration.AddUserForm.passNotEqual'));
          isValidForm = false;
        }
      } else if (typeof this.currentUser.company_id === 'undefined') {
        this.$f7.dialog.alert(this.$t('Configuration.AddUserForm.companyUserForm'));
        isValidForm = false;
      }
      return isValidForm;
    },
    ...mapActions('UserData', ['getUserById', 'setCurrentUser',
      'resetCurrentUser', 'resetCurrentProviderCustomer']),
  },
};
</script>
<style lang="scss" scoped>
</style>
