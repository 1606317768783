import Api from '../../services/Api';
import Helpers from '../../js/helpers';

export default {
  namespaced: true,
  state: {
    currentUser: {},
    customersAvailables: [],
    providersAvailables: [],
    isCompanyOcph: false,
    companies: [],
  },
  actions: {
    async fetchUsers({ commit }) {
      try {
        const xhr = await Api.fetchUsers();
        const users = JSON.parse(xhr.response).data;
        commit('fetchUsers', users);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
    async fetchCompanies({ commit }) {
      try {
        const xhr = await Api.fetchCompanies();
        const companies = JSON.parse(xhr.response);
        commit('fetchCompanies', companies);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
    async getUserById({ commit }, userId) {
      try {
        const xhr = await Api.getUser(userId);
        const user = JSON.parse(xhr.response);
        commit('setCurrentUser', user);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
    async createUser({ commit }, user) {
      try {
        const xhr = await Api.createUser(user);
        const newUser = JSON.parse(xhr.response);
        commit('setUser', newUser);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
    async editUser({ commit }, user) {
      try {
        const xhr = await Api.editUser(user);
        const updatedUser = JSON.parse(xhr.response);
        commit('setUser', updatedUser);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
    updateCurrentUserProperty({ commit }, { property, newValue }) {
      commit('updateCurrentUserProperty', { property, newValue });
    },
    setCurrentUser({ commit }, value) {
      commit('setCurrentUser', value);
    },
    resetCurrentUser({ commit }) {
      const resetValue = {};
      commit('setCurrentUser', resetValue);
    },
    setCurrentErpCompanyEdit({ commit }, { property, newValue }) {
      commit('setCurrentErpCompany', { property, newValue });
    },
    resetCurrentProviderCustomer({ commit }) {
      const resetValue = [];
      commit('resetCurrentProviderCustomer', resetValue);
    },
    setCurrentErpCompany({ commit, state }, value) {
      for (const companyErp of state.companiesErp) {
        if (companyErp.id === value) {
          commit('setCurrentErpCompany', JSON.parse(JSON.stringify(companyErp)));
        }
      }
    },
  },
  mutations: {
    fetchCompanies(state, value) {
      state.companies = value;
    },
    fetchUsers(state, value) {
      state.users = value;
    },
    updateCurrentUserProperty(state, { property, newValue }) {
      state.currentUser[property] = newValue;
    },
    setCurrentUser(state, value) {
      state.currentUser = value;
    },
    resetCurrentUser(state, value) {
      state.currentUser = value;
    },

  },
};
