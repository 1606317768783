<template>
  <f7-page>
    <navbar :text="$t('LeftPanel_subtitle_consumtion')" />
    <div>
      <Filters
        :companies="companies"
      />
      <Consumption
        :consumption="consumption"
      />
    </div>
  </f7-page>
</template>

<script>

import { mapActions, mapState } from 'vuex';
import navbar from '../../components/navbar/NavBar.vue';
import Consumption from '../../components/consumption/index.vue';
import Filters from '../../components/consumption/consumptionFilters/index.vue';

export default {
  name: 'PageOrder',
  components: {
    Consumption,
    navbar,
    Filters,
  },
  data() {
    return {
      loaded: false,
      keyName: 'orders',
    };
  },
  computed: {
    ...mapState('Consumption', ['companies', 'consumption']),
  },
  async beforeMount() {
    this.$f7.preloader.show();
    try {
      await this.fetchCompanies();
      await this.fetchConsumption();
    } catch (error) {
      console.error(error);
      if (error.message.includes('500')) {
        this.$f7.dialog.alert(this.$t('error_500'));
      } else {
        this.$f7.dialog.alert(this.$t(`${error}`));
      }
    } finally {
      this.loaded = true;
      this.$f7.preloader.hide();
    }
  },
  methods: {
    ...mapActions('Consumption', ['fetchCompanies', 'fetchConsumption']),
  },

};
</script>

<style lang="scss">

</style>
