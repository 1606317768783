export default {
  namespaced: true,
  state: {
    popupFiltersVisible: false,
    currentPage: '/',
    currentTab: 'home',
    currentSubTab: '',
  },
  mutations: {
    SET_POPUP_FILTERS_VISIBLE(state, value) {
      state.popupFiltersVisible = value;
    },
    SET_CURRENT_PAGE(state, value) {
      state.currentPage = value;
    },
    SET_CURRENT_TAB(state, value) {
      state.currentTab = value;
    },
    SET_CURRENT_SUBTAB(state, value) {
      state.currentSubTab = value;
    },
  },
  actions: {
    setPopupFiltersVisible(context, value) {
      context.commit('SET_POPUP_FILTERS_VISIBLE', value);
    },
    setCurrentPage(context, value) {
      context.commit('SET_CURRENT_PAGE', value);
    },
    setCurrentTab(context, value) {
      context.commit('SET_CURRENT_TAB', value);
    },
    setCurrentSubTab(context, value) {
      context.commit('SET_CURRENT_SUBTAB', value);
    },
  },
};
