<template>
  <f7-page
    name="loginPage"
    login-screen
  >
    <login
      :has-restore-pass="true"
      :has-remember-me="true"
      :has-need-help="true"
    />
  </f7-page>
</template>

<script>
import login from '../../components/accounts/Login.vue';

export default {
  components: {
    login,
  },
  data() {
    return {
      login,
    };
  },
};
</script>

<style lang="scss" scoped>

</style>
