<!-- CREAR -->
<template>
  <div class="content-devx">
    <DxForm
      id="userForm"
    >
      <DxGroupItem
        css-class="content-combo"
        :col-count="1"
      >
        <DxGroupItem>
          <DxSimpleItem
            name="name"
            data-field="name"
            :editor-options="{
              onValueChanged: onChangeName,
              placeholder: $t('Nombre ...'),
            }"
          >
            <DxLabel :text="$t('NOMBRE')" />
            <DxRequiredRule :message="$t('Field_Required')" />
          </DxSimpleItem>
          <DxSimpleItem
            v-if="user.contact.staff"
            name="company_name"
            data-field="company_name"
            editor-type="dxSelectBox"
            :editor-options="{
              items: companies,
              displayExpr: 'value',
              valueExpr: 'id',
              onValueChanged: onChangeCompany,
            }"
          >
            <DxLabel :text="$t('Compañia')" />
            <DxRequiredRule :message="$t('Field_Required')" />
          </DxSimpleItem>

          <DxSimpleItem
            name="surname"
            data-field="surname"
            :editor-options="{
              onValueChanged: onChangeSurname,
              placeholder: $t('Apellido ...'),
            }"
          >
            <DxLabel :text="$t('APELLIDO')" />
            <DxRequiredRule :message="$t('Field_Required')" />
          </DxSimpleItem>

          <DxSimpleItem
            name="email"
            data-field="email"
            :editor-options="{
              onValueChanged: onChangeEmail,
              placeholder: $t('Email ...'),
            }"
          >
            <DxLabel :text="$t('EMAIL')" />
            <DxRequiredRule :message="$t('Field_Required')" />
          </DxSimpleItem>

          <DxSimpleItem
            name="password"
            data-field="password"
            :editor-options="{
              onValueChanged: onChangePassword,
              placeholder: $t('Indique su contraseña ...'),
            }"
          >
            <DxRequiredRule :message="$t('Field_Required')" />
            <DxLabel :text="$t('CONTRASEÑA')" />
          </DxSimpleItem>
          <DxSimpleItem
            name="confirmPassword"
            data-field="confirmPassword"
            :editor-options="{
              onValueChanged: onChangeConfirmPassword,

              placeholder: $t('Repita su contraseña ...'),

            }"
          >
            <DxLabel :text="$t('CONFIRMAR')" />
          </DxSimpleItem>
        </DxGroupItem>
      </Dxgroupitem>
    </DxForm>
    <div>
      <label
        class="margin dx-field-item-label dx-field-item-label-location-left"
        for="dx_dx-6598e3e6-1fec-d875-6768-c9bd57af04a5_password"
      >
        <span
          class="dx-field-item-label-content"
          style="width: 92px;"
        >
          <span class="dx-field-item-label-text">Hacer usuario Activo</span>
        </span>
      </label>
      <DxCheckBox
        :value="status"
        :text="$t('ACTIVO').toUpperCase()"
        class="padding"
        @value-changed="OnSelectedMakeActive"
      />
    </div>
    <div v-if="user.contact.staff">
      <label
        class="margin dx-field-item-label dx-field-item-label-location-left"
        for="dx_dx-6598e3e6-1fec-d875-6768-c9bd57af04a5_password"
      >
        <span
          class="dx-field-item-label-content"
          style="width: 92px;"
        >
          <span class="dx-field-item-label-text">Hacer usuario STAFF :</span>
        </span>
      </label>
      <DxCheckBox
        :value="staff"
        :text="$t('STAFF').toUpperCase()"
        class="padding"
        @value-changed="OnSelectedMakeStaff"
      />
    </div>
    <div v-if="user.contact.admin || user.contact.staff">
      <label
        class="margin dx-field-item-label dx-field-item-label-location-left"
        for="dx_dx-6598e3e6-1fec-d875-6768-c9bd57af04a5_password"
      >
        <span
          class="dx-field-item-label-content"
          style="width: 92px;"
        >
          <span class="dx-field-item-label-text">Hacer usuario ADMN:</span>
        </span>
      </label>
      <DxCheckBox
        :value="admin"
        :text="$t('ADMIN').toUpperCase()"
        class="padding"
        @value-changed="OnSelectedMakeAdmin"
      />
    </div>
  </div>
</template>
<script>

import { mapActions, mapState } from 'vuex';
import {
  DxForm,
  DxSimpleItem,
  DxGroupItem,
  DxRequiredRule,
  DxLabel,
} from 'devextreme-vue/form';
import DxCheckBox from 'devextreme-vue/check-box';

export default {
  name: 'UserForm',
  components: {
    DxForm,
    DxSimpleItem,
    DxGroupItem,
    DxRequiredRule,
    DxLabel,
    DxCheckBox,

  },
  props: {
    currentUser: {
      type: Object,
      default: () => {},
    },
    companiesErp: {
      type: Array,
      default: () => [],
    },
    customersAvailables: {
      type: Array,
      default: () => [],
    },
    providersAvailables: {
      type: Array,
      default: () => [],
    },
    isCompanyOcph: { type: Boolean, default: false },
    editForm: { type: Boolean, default: false },
    contact: { type: Object, default: () => {} },
  },
  data() {
    return {
      admin: false,
      staff: false,
      status: false,
    };
  },
  beforeMount() {

  },
  computed: {
    ...mapState('authentication', ['user']),
    ...mapState('UserData', ['currentUser', 'currentCompany', 'companies']),
  },
  methods: {
    onSelectedErpUser(e) {
      if (e.event) {
        this.setCurrentUser(e.value);
      }
    },
    onSelectedErpCompany(e) {
      if (e.event) {
        this.setCurrentErpCompany(e.value);
      }
    },
    onChangeName(e) {
      if (e.event) {
        this.updateCurrentUserProperty({ property: 'name', newValue: e.value });
      }
    },
    onChangeSurname(e) {
      if (e.event) {
        this.updateCurrentUserProperty({ property: 'surname', newValue: e.value });
      }
    },
    onChangeCompany(e) {
      if (e.event) {
        this.updateCurrentUserProperty({ property: 'company_id', newValue: e.value });
      }
    },
    onChangeEmail(e) {
      if (e.event) {
        this.updateCurrentUserProperty({ property: 'email', newValue: e.value });
      }
    },
    onChangeUsername(e) {
      if (e.event) {
        this.updateCurrentUserProperty({ property: 'username', newValue: e.value });
      }
    },
    onChangePassword(e) {
      if (e.event) {
        this.updateCurrentUserProperty({ property: 'password', newValue: e.value });
      }
    },
    onChangeConfirmPassword(e) {
      if (e.event) {
        this.updateCurrentUserProperty({ property: 'confirmPassword', newValue: e.value });
      }
    },

    OnSelectedMakeStaff(e) {
      this.updateCurrentUserProperty({
        property: 'staff',
        newValue: e.value,
      });
    },
    OnSelectedMakeAdmin(e) {
      this.updateCurrentUserProperty({
        property: 'admin',
        newValue: e.value,
      });
    },
    OnSelectedMakeActive(e) {
      this.updateCurrentUserProperty({
        property: 'status',
        newValue: e.value,
      });
    },
    ...mapActions('UserData', ['updateCurrentUserProperty', 'setCurrentUser', 'fetchCompanies']),
  },
};
</script>

<style lang="scss" scoped>
@import './UserForm.styles.scss';
</style>
