<template>
  <div
    class="content-devx"
  >
    <DxForm
      id="orderForm"
    >
      <DxGroupItem
        css-class="content-combo"
        :col-count="2"
      >
        <DxSimpleItem
          name="client"
          :data-field="$t('newOrder.newOrder_client')"
          :editor-options="{
            value: newOrder.client,
            onValueChanged: onChangeClient,
          }"
        >
          <DxRequiredRule :message="$t('newOrder.newUser.DxCostForm_Id_required')" />
        </DxSimpleItem>
        <DxSimpleItem
          name="order"
          :data-field="$t('newOrder.newOrder_order')"
          :editor-options="{
            value: newOrder.order,
            onValueChanged: onChangeOrder,
          }"
        >
          <DxRequiredRule :message="$t('newOrder.newUser.DxCostForm_Id_required')" />
        </DxSimpleItem>
        <DxSimpleItem
          name="issued"
          :data-field="$t('newOrder.newOrder_emit')"
          :editor-options="{
            value: newOrder.issued,
            onValueChanged: onChangeIssued,
          }"
        >
          <DxRequiredRule :message="$t('newOrder.newUser.DxCostForm_Id_required')" />
        </DxSimpleItem>
        <DxSimpleItem
          name="delivery"
          :data-field="$t('newOrder.newOrder_delivery')"
          :editor-options="{
            value: newOrder.delivery,
            onValueChanged: onChangeDelivery,
          }"
        >
          <DxRequiredRule :message="$t('newOrder.newUser.DxCostForm_price_required')" />
        </DxSimpleItem>
        <DxSimpleItem
          name="email"
          :data-field="$t('newOrder.newOrder_address')"
          :col-span="2"
          :editor-options="{
            value: newOrder.email,
            onValueChanged: onChangeEmail,
          }"
        >
          <DxRequiredRule :message="$t('newOrder.newUser.DxCostForm_Id_required')" />
        </DxSimpleItem>
      </Dxgroupitem>
    </DxForm>
  </div>
</template>
<script>

import { mapState, mapActions } from 'vuex';
import {
  DxForm,
  DxSimpleItem,
  DxGroupItem,
  DxRequiredRule,
} from 'devextreme-vue/form';
import * as overlay from 'devextreme/ui/overlay';

export default {
  name: 'NewOrder',
  components: {
    DxForm,
    DxSimpleItem,
    DxGroupItem,
    DxRequiredRule,
  },
  props: {
    editForm: { type: Boolean, default: false },
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapState('Order', ['isNewUser', 'newOrder']),
  },
  methods: {
    back() {
      this.setIsNewUser(false);
    },
    register() {
      this.resetNewUser();
    },
    onChangeClient(e) {
      this.setClient(e.value);
    },
    onChangeIssued(e) {
      this.setIssued(e.value);
    },
    onChangeDelivery(e) {
      this.setDelivery(e.value);
    },
    onChangeEmail(e) {
      this.setEmail(e.value);
    },
    onChangeOrder(e) {
      this.setOrder(e.value);
    },
    ...mapActions('Order', ['setClient', 'setIssued', 'setTypeDelivery', 'setEmail',
      'setOrder', 'resetNewOrder']),
  },
};
</script>

<style lang="scss" scope>
    .content-devx {
        width: auto;
    }
  .content-combo{
    .dx-item{
      .dx-item-content{
        label{
          min-width: 160px;
        }
      }
    }
  }
  .botonPrimary {
      width: 100%;
  }
  .botonSecundary {
      width: 100%;
  }
</style>
