<template>
  <f7-panel
    left
    reveal
    resizable
    class="panel panel-left not-animated content-menu"
    :opened="showToDefault"
    :visible-breakpoint="1024"
    :collapsed-breakpoint="1023"
  >
    <f7-page>
      <div class="content-menu-header">
        <div class="menu-header-item">
          <span class="version-app">1.0.0</span>
          <img
            class="image_logo"
            :src="loadLogo"
          >
        </div>
      </div>
      <f7-list
        accordion-list
        class="content-list-menu"
      >
        <ul>
          <li
            v-for="(item, index) in menuList"
            :key="index"
            class="accordion-item"
          >
            <template>
              <a
                :href="item.url"
                class="item-content item-link"
              >
                <div class="item-inner">
                  <div class="item-title">{{ $t(item.name) }}</div>
                </div>
              </a>
              <f7-accordion-content>
                <f7-list media-list>
                  <f7-list-item
                    v-for="(itemMenu, index2) in item.menus"
                    :key="index2"
                    class="panel-close close"
                    :class="{highlight:getActualMenuSelected == itemMenu.url}"
                    accordion-list
                    @click="navigateTo(itemMenu.url)"
                  >
                    <f7-icon
                      v-if="(typeof itemMenu.icon !== `undefined`)"
                      slot="media"
                      :class="`icon-menu ${itemMenu.icon}`"
                      :link="itemMenu.url"
                      width="32"
                      height="32"
                    />
                    <a
                      v-if="(typeof(itemMenu.icon)!=='undefined')"
                    >{{ $t(itemMenu.name) }}</a>
                    <a
                      v-else
                    >{{ $t(itemMenu.name) }}</a>
                  </f7-list-item>
                </f7-list>
              </f7-accordion-content>
            </template>
          </li>
        </ul>
      </f7-list>
    </f7-page>
  </f7-panel>
</template>

<script>
import {
  mapState, mapActions, mapGetters,
} from 'vuex';
import {
  APPLICATION_NAME, APP_LOGO,
} from '../js/constants';
import store from '../store/index';
import EventBus from '../js/event-bus';

export default {
  name: 'LeftPanel',
  store,
  props: {
    elements: {
      type: Array,
      default: () => [],
    },
    showToDefault: {
      type: Boolean,
      default: true,
    },
    version: {
      type: String,
      default: '1.0.0',
    },
  },
  data() {
    return {
      APPLICATION_NAME,
      APP_LOGO,
      alwaysOpen: 0,
      menuList: [],
      userPermissions: [],
    };
  },
  computed: {
    appName() {
      return this.APPLICATION_NAME;
    },
    appLogo() {
      return localStorage.getItem('applogo') || this.APP_LOGO;
    },
    loadLogo() {
      return this.user.contact.company_logo;
    },
    ...mapState('authentication', ['user']),
    ...mapGetters('authentication', ['getActualMenuSelected']),
  },
  mounted() {
    EventBus.$on('setUserPermissions', (userPermissions) => {
      this.userPermissions = userPermissions.permissions;
      this.menuList = this.checkMenuPermissions(this.elements);
    });
  },
  methods: {
    checkMenuPermissions(element) {
      const menu = [];
      for (const idx in element) {
        // si tiene menus y tener algun elemento
        if (element[idx].menus && element[idx].menus.length > 0) {
          // los recorremos recursivamente (cada submenu)
          element[idx].menus = this.checkMenuPermissions(element[idx].menus);
          // si existe algun submenu -> elemento padre va en el resultado
          if (element[idx].menus.length > 0) {
            menu.push(element[idx]);
          }
          // sino, checkeamos permisos
        } else if (((element[idx].user && !(this.user.contact.staff || this.user.contact.admin)) || (element[idx].staff && this.user.contact.staff) || (element[idx].admin && this.user.contact.admin))) {
          if (this.isGranted(element[idx]) && (this.$t(element[idx].name) !== '')) {
            menu.push(element[idx]);
          }
        }
      }
      return menu;
    },
    navigateTo(path) {
      if (path === '/accounts/loginPage/') {
        // store.dispatch('authentication/logout');
        this.logoutPressed();
      } else {
        this.setActualMenuSelected(path);
        this.$f7.views.main.router.navigate(path, {
          reloadCurrent: true,
          clearPreviousHistory: true,
        });
      }
    },
    logoutPressed() {
      const self = this;
      self.$f7.panel.close('left', false);
      self.logout();
    },
    isGranted(menu) {
      // return (((menu.user && !(this.user.contact.staff || this.user.contact.admin)) || (menu.staff && this.user.contact.staff) || (menu.admin && this.user.contact.admin)));
      if (menu.permission === 'ALL') {
        return true;
      }
      const indice = this.userPermissions.indexOf(menu.permission);
      return (this.userPermissions[indice]);
    },
    ...mapActions('authentication', ['logout', 'setActualMenuSelected']),
  },
};
</script>

<style lang="scss">
.content-list-menu {
  ul {
    li {
      &.accordion-item
      a {
        .item-inner {
          justify-content: flex-start !important;
          align-content: center;
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;

          .item-title {
            color: #000000;
            padding-left: 0;
          }

          &::after {
            height: 0;
          }

          &::before {
            color: var(--fr-nutella) !important;
          }
        }
      }

      &.accordion-item-opened {
        .item-content:not(:last-child) {
          border-bottom: 1px solid var(--f7-list-item-border-color);
        }
      }

      &.panel-close {
        &:hover {
          background: linear-gradient(270deg, rgba(251, 247, 239, 0.5), #ffffff);

          a {
            color: var(--fr-chocolat) !important;
          }
        }

        &.highlight {
          background: linear-gradient(270deg, #FBF7EF, #ffffff) !important;

          a {
            color: var(--adp-blue) !important;
            font-weight: 600;
          }
        }

        .item-content:not(:last-child) {
          border-bottom: 1px solid var(--f7-list-item-border-color);

          .item-inner {
            &::after {
              height: 0;
            }
          }
        }
      }

      a {
        color: var(--fr-chocolat) !important;
      }

      a:focus,
      a:hover {
        color: var(--adp-blue) !important;
      }
    }
  }
}

li.accordion-item.accordion-item-opened {
  background: #FCF8F5;
}

.toolbar_panel {
  bottom: 0px !important;
  padding: 5px 10px;
}

.chip-label {
  font-weight: 600;
  font-size: 14px;
  line-height: 1.4;
}

.list ul ul {
  padding-left: 0px;
}

.item-content {
  cursor: pointer;
}

.content-menu-header {
  background: white;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-content: stretch;
  align-items: flex-start;
  padding: 2px 5px 2px 5px;
}

.content-menu .list ul {
  background: white;

}

.content-menu .list-group-title {
  border-bottom: 1px solid #ececec;
}

.content-menu .page-content {
  background: #F7F8FA;
}

//TODO: Ver porqué se ha alinea a la derecha | Alinear nombre del menú a la izquierda
.content-menu .list .item-inner {
  justify-content: flex-start;
  align-content: center;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.item-media {
  margin-left: 0 !important;
  min-width: 24px !important;
}

.icon-menu {
  width: 24px;
  height: 24px;
}

.icon-home {
  background-image: url('../static/img/icon_inicio.svg');

}

.icon-taks {
  background-image: url('../static/img/ic_setting.svg');
}

.icon-logout {
  background-image: url('../static/img/ic_exitout.svg');
}

div.item-inner a {
  color: #003D3E !important;
}

.image_logo {
  width: 90%;
  padding: 10px 5px 5px 10px;
}

.panel {
  min-width: 100px;
  max-width: 90vw;
}
</style>
