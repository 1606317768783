<template>
  <f7-block>
    <input
      :id="'fileInput_'+keyName"
      type="file"
      hidden
      accept="application/pdf"
      @change="onFileSelected"
    >

    <div
      style="justify-content: flex-start; float: left;"
      class="dx-item dx-toolbar-item dx-toolbar-button"
      @click="selectFileInput.click()"
    >
      <div class="dx-item-content dx-toolbar-item-content">
        <div
          class="dx-button dx-button-normal dx-button-mode-contained
          dx-widget add-task dx-button-has-icon dx-button-has-text"
          aria-label="FICHERO"
          tabindex="0"
          role="button"
        >
          <div class="dx-button-content">
            <i class="dx-icon dx-icon-add" /><span class="dx-button-text">CARGAR FICHERO</span>
          </div>
        </div>
      </div>
    </div>

    <div
      class="content-calendar"
    >
      <v-date-picker
        v-model="range"
        is-range
        :masks="format"
        timezone="UTC"
      >
        <template v-slot="{ inputValue, inputEvents }">
          <div class="content-calendar__inputs">
            <div>
              <svg
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0
                   002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                />
              </svg>
              <input
                :value="inputValue.start"
                v-on="inputEvents.start"
              >
            </div>
            <span class="content-calendar__span">
              <svg
                viewBox="0 0 24 24"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M14 5l7 7m0 0l-7 7m7-7H3"
                />
              </svg>
            </span>
            <div>
              <svg
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2
                  0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                />
              </svg>
              <input
                :value="inputValue.end"
                v-on="inputEvents.end"
              >
            </div>
          </div>
        </template>
      </v-date-picker>
      <div class="content-calendar__buttons">
        <button
          class="dx-btn-cancel"
          style="background-color:  #f8f8f8; color: Black;"
          type="button"
          @click="clearFilter"
        >
          {{ $t('CalendarInitEndDate_btnClear') }}
        </button>
        <button
          class="botonPrimary"
          type="button"
          @click="setFilter"
        >
          {{ $t('CalendarInitEndDate_btnSearch') }}
        </button>
      </div>
    </div>
  </f7-block>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import moment from 'moment-timezone';
import EventBus from '../js/event-bus';

export default {
  name: 'CalendarInitEndDate',
  props: {
    keyName: {
      type: String,
      default: '',
    },
    maxRange: {
      type: Number,
      default: null,
    },
    start: {
      type: String,
      default: moment()
        .subtract(7, 'days')
        .format('YYYY-MM-DD'),
    },
    end: {
      type: String,
      default: moment()
        .format('YYYY-MM-DD'),
    },
  },
  data() {
    return {
      selectedFile: {},
      range: {
        start: moment()
          .subtract(7, 'days')
          .format('YYYY-MM-DD'),
        end: moment()
          .format('YYYY-MM-DD'),
      },
      format: {
        input: ['YYYY-MM-DD'],
      },
    };
  },
  computed: {
    ...mapState('SaleAccount', ['newSaleAccount']),
    selectFileInput() {
      return document.getElementById(`fileInput_${this.keyName}`);
    },
    ...mapGetters('CalendarInitEndDate', ['actualFilter']),
  },
  beforeMount() {
    if (typeof this.actualFilter[this.keyName] !== 'undefined') {
      this.range.start = moment.utc(this.actualFilter[this.keyName].init)
        .format('YYYY-MM-DD');
      this.range.end = moment.utc(this.actualFilter[this.keyName].end)
        .format('YYYY-MM-DD');
    }
    EventBus.$on('clearFilter', this.clearFilter);
  },
  methods: {

    async onFileSelected(event) {
      console.log('keyname', this.keyName);
      const reader = new FileReader();
      const newFile = event.target.files[0];

      reader.onload = (e) => {
        const selectedFile = e.target.result;
        this.setNameAttachment(newFile.name);
        this.setAttachment(selectedFile);
        this.postAnalaysis();
      };

      reader.onerror = (error) => {
        this.$f7.dialog.alert(`${this.$t('errorinformation.reader')} ${error}`);
      };
      reader.readAsDataURL(newFile);
    },

    async postAnalaysis() {
      this.$f7.preloader.show();
      try {
        await this.uploadSaleAccount(this.newSaleAccount);

        this.setIsEdit(false);
        this.setIsUploaded(true);

        this.$f7.views.main.router.navigate('/sale-accounts/', { reloadCurrent: true });
      } catch (error) {
        console.error(error);
        this.$f7.dialog.alert(`${this.$t('errorinformation.standar')}`);
      } finally {
        this.$f7.preloader.hide();
      }
    },

    setFilter() {
      let redirect = true;
      if (this.range.start !== '' && this.range.end !== '') {
        if (this.maxRange !== null) {
          const currentRange = moment(this.range.end)
            .diff(moment(this.range.start), 'days');
          if (this.maxRange < currentRange) {
            this.$f7.dialog.alert(`${this.$t('Date_OutOfRange')} ${this.maxRange}`);
            redirect = false;
          } else {
            this.setActualFilter({
              keyName: this.keyName,
              init: moment.tz(this.range.start, 'UTC')
                .format('YYYY-MM-DD'),
              end: moment.tz(this.range.end, 'UTC')
                .format('YYYY-MM-DD'),
            });
          }
        } else {
          this.setActualFilter({
            keyName: this.keyName,
            init: moment.tz(this.range.start, 'UTC')
              .format('YYYY-MM-DD'),
            end: moment.tz(this.range.end, 'UTC')
              .format('YYYY-MM-DD'),
          });
        }
      }
      if (redirect) {
        this.$f7.views.main.router.navigate(`/${this.keyName}/`, { reloadCurrent: true });
      }
    },
    clearFilter() {
      console.log('clearFilter', this.keyName);
      this.range = {
        start: this.start,
        end: this.end,
      };
      this.setActualFilter({
        keyName: this.keyName,
        init: this.range.start,
        end: this.range.end,
      });
    },
    ...mapActions('CalendarInitEndDate', ['setActualFilter', 'setClearFilter']),
    ...mapActions('SaleAccount', ['uploadSaleAccount', 'setAttachment', 'setIsEdit', 'setNameAttachment', 'setIsUploaded', 'resetNewSaleAccount']),
  },
};
</script>

<style lang="scss">
.content-calendar {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 0;
  margin-bottom: 10px;

  *, :after, :before {
    box-sizing: border-box;
    border: 0 solid #e2e8f0;
  }

  &__inputs {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    div {
      position: relative;
      flex-grow: 1;

      svg {
        width: 1rem;
        --text-opacity: 1;
        color: #718096;
        color: rgba(113, 128, 150, var(--text-opacity));
        position: absolute;
        pointer-events: none;
        margin-left: .5rem;
        margin-right: .5rem;
        height: 100%;
        display: block;
      }

      input {
        width: 100%;
        height: 34px;
        padding: 7px 18px 8px;
        padding-left: 2rem;
        flex-grow: 1;
        border-width: 1px;
        border-radius: .25rem;
        --bg-opacity: 1;
        background-color: #f8f8f8;
        font-family: inherit;
        font-size: 100%;
        margin: 0;
        text-rendering: auto;
        letter-spacing: normal;
        word-spacing: normal;
        text-transform: none;
        text-indent: 0px;
        text-shadow: none;
        display: inline-block;
        text-align: start;
        appearance: textfield;
        -webkit-rtl-ordering: logical;
        cursor: text;
        margin: 0em;
        font: 400 13.3333px Arial;
      }
    }
  }

  &__span {
    margin: .5rem;
    flex-shrink: 0;

    svg {
      width: 1rem;

      path {
        stroke: currentColor;
      }
    }
  }

  &__buttons {
    display: flex;
    --text-opacity: 1;
    color: #718096;
    color: rgba(113, 128, 150, var(--text-opacity));
    stroke: currentColor;

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 34px;
      min-width: 100px;
      overflow: hidden;
      position: relative;
      margin-right: 6px !important;
      margin-left: 6px !important;
      border-radius: 3px !important;
      color: #FAF8F2;

      &.dx-btn-success:focus {
        outline: none !important;
      }

      &.dx-btn-cancel:focus {
        outline: none !important;
      }
    }
  }
}
</style>
