<template>
  <div class="content-devx">
    <DxDataGrid
      :data-source="consumption"
      :allow-column-reordering="true"
      :show-borders="true"
      :show-column-lines="true"
      :show-row-lines="false"
      :column-auto-width="true"
      :row-alternation-enabled="true"
      :word-wrap-enabled="true"
      @exporting="onExporting"
    >
      <DxExport
        :enabled="true"
      />
      <DxStateStoring
        :enabled="true"
        type="localStorage"
        storage-key="DxSaleAccounts"
      />
      <DxPaging :page-size="10" />
      <DxPager
        :show-page-size-selector="true"
        :show-info="true"
      />
      <DxHeaderFilter :visible="true" />
      <DxColumnFixing :enabled="true" />
      <DxColumn
        data-field="company"
        :caption="$t('Compañia')"
        :allow-header-filtering="true"
        alignment="left"
      />
      <DxColumn
        data-field="date"
        :caption="$t('Mes')"
        :allow-header-filtering="false"
        alignment="left"
      />
      <DxColumn
        data-field="call_counter"
        :caption="$t('Consumo')"
        :allow-header-filtering="false"
        alignment="left"
      />
    </DxDataGrid>
  </div>
</template>

<script>
import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxColumnFixing,
  DxHeaderFilter,
  DxStateStoring,
  DxPager,
  DxExport,

} from 'devextreme-vue/data-grid';
import { exportDataGrid } from 'devextreme/excel_exporter';
import ExcelJS from 'exceljs';
import saveAs from 'file-saver';

export default {
  name: 'ConsumptionSaleAccount',
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxColumnFixing,
    DxHeaderFilter,
    DxStateStoring,
    DxPager,
    DxExport,

  },
  props: {
    consumption: { type: Array, default: () => [] },
  },
  data() {
    return {

    };
  },
  methods: {
    // EXPORT EXCEL
    onExporting(e) {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('Consumo');
      exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
      }).then(() => {
        workbook.xlsx.writeBuffer()
          .then((buffer) => {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'ConsumoCDV.xlsx');
          });
      });
      e.cancel = true;
    },

  },
};
</script>

<style>
</style>
