var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('DxForm',{attrs:{"id":"costForm"}},[_c('DxGroupItem',{attrs:{"css-class":"content-combo"}},[_c('DxSimpleItem',{attrs:{"name":"name","data-field":_vm.$t('newUser.newUser_name'),"editor-options":{
          value: _vm.newUser.name,
          onValueChanged: _vm.onChangeName,
        }}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('newUser.DxCostForm_Id_required')}})],1),_vm._v(" "),_c('DxSimpleItem',{attrs:{"name":"surname","data-field":_vm.$t('newUser.newUser_surname'),"editor-options":{
          value: _vm.newUser.surname,
          onValueChanged: _vm.onChangeSurname,
        }}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('newUser.DxCostForm_Id_required')}})],1),_vm._v(" "),_c('DxSimpleItem',{attrs:{"name":"email","data-field":_vm.$t('newUser.newUser_email'),"editor-options":{
          value: _vm.newUser.email,
          onValueChanged: _vm.onChangeEmail,
        }}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('newUser.DxCostForm_Id_required')}})],1),_vm._v(" "),_c('DxSimpleItem',{attrs:{"name":"company","data-field":_vm.$t('newUser.company'),"editor-options":{
          value: _vm.newUser.company_name,
          onValueChanged: _vm.onChangeCompany,
        }}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('newUser.DxCostForm_Id_required')}})],1),_vm._v(" "),_c('DxSimpleItem',{attrs:{"name":"password","data-field":_vm.$t('newUser.newUser_password'),"editor-options":{
          value: _vm.newUser.password,
          onValueChanged: _vm.onChangePassword,
          mode: 'password',
        }}}),_vm._v(" "),_c('DxSimpleItem',{attrs:{"name":"confirmPassword","data-field":_vm.$t('newUser.newUser_confirmPassword'),"editor-options":{
          value: _vm.newUser.confirmPassword,
          onValueChanged: _vm.onChangeConfirmPassword,
          mode: 'password',
        }}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }