<template>
  <div
    class="content-devx"
  >
    <DxForm
      id="itemSearcher-form"
      :show-colon-after-label="false"
      :col-count="3"
    >
      <DxGroupItem>
        <DxSimpleItem
          editor-type="dxSelectBox"
          :editor-options="{
            items: companies,
            displayExpr:'value',
            valueExpr:'id',
            placeholder: $t('Compañia...'),
          }"
        />
      </DxGroupItem>
      <DxGroupItem>
        <DxSimpleItem
          editor-type="dxDateBox"
          :editor-options="{
            displayFormat: 'MM/yyyy',
            placeholder: $t('Fecha inicio...'),
            onValueChanged: initDateSelected
          }"
        />
      </DxGroupItem>
      <DxGroupItem>
        <DxSimpleItem
          editor-type="dxDateBox"
          :editor-options="{
            displayFormat: 'MM/yyyy',
            placeholder: $t('Fecha fin...'),
            onValueChanged: endDateSelected
          }"
        />
      </DxGroupItem>
    </DxForm>
    <f7-row>
      <f7-col width="70" />
      <f7-col
        width="15"
        style=""
      >
        <f7-button
          class=" dx-btn-search margin-right"
          raised
          styling-mode="contained"
          @click="clean"
        >
          {{ $t('LIMPIAR') }}
        </f7-button>
      </f7-col>
      <f7-col
        width="15"
        style="margin-right: 10px;"
      >
        <f7-button
          class="dx-btn-search margin-right"
          raised
          styling-mode="contained"
          @click="search"
        >
          {{ $t('BUSCAR') }}
        </f7-button>
      </f7-col>
    </f7-row>
  </div>
</template>
<script>
import {
  DxForm,
  DxSimpleItem,
  DxGroupItem,
} from 'devextreme-vue/form';
import moment from 'moment';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'ConverterFilter',
  components: {
    DxForm,
    DxSimpleItem,
    DxGroupItem,
  },
  props: {
    companies: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapState('Consumption', ['initDate', 'endDate', 'company']),
  },
  methods: {

    initDateSelected(e) {
      this.setInitDate(moment(e.value).format('YYYY-MM-DD'));
    },
    endDateSelected(e) {
      this.setEndDate(moment(e.value).format('YYYY-MM-DD'));
    },
    search() {
      this.fetchConsumption();
    },
    clean() {
      this.setInitDate('');
      this.setEndDate('');
      this.fetchConsumption();
    },
    ...mapActions('Consumption', ['setEndDate', 'setInitDate', 'fetchConsumption']),
  },
};
</script>
<style>

</style>
