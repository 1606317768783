import Vue from 'vue';
import Vuex from 'vuex';
// import VuexPersistence from 'vuex-persist';

// Modules
import authentication from './modules/authentication';
import User from './modules/User';
import Utilities from './modules/Utilities';
import AccessControl from './modules/AccessControl';
import Order from './modules/Order';
import SaleAccount from './modules/SaleAccount';
import Consumption from './modules/Consumption';
import ConsumptionSaleAccount from './modules/ConsumptionSaleAccount';
import CalendarInitEndDate from './modules/CalendarInitEndDate';
import UserData from './modules/UserData';
import Companies from './modules/Companies';

Vue.use(Vuex);
const store = new Vuex.Store({
  modules: {
    authentication,
    User,
    Utilities,
    Order,
    SaleAccount,
    AccessControl,
    CalendarInitEndDate,
    Consumption,
    ConsumptionSaleAccount,
    UserData,
    Companies,
  },
  // plugins: [new VuexPersistence({
  // storage: window.localStorage,
  // }).plugin],
});

store.dispatch('authentication/initialize');

export default store;
