// import i18next from 'i18next';
import Api from '../../services/Api';
import Helpers from '../../js/helpers';

export default {
  namespaced: true,
  state: {
    consumption: [],
    companies: [],
    initDate: '',
    endDate: '',
    company: '',
  },
  actions: {
    setInitDate(context, value) {
      context.commit('setInitDate', value);
    },
    setEndDate(context, value) {
      context.commit('setEndDate', value);
    },
    setCompany(context, value) {
      context.commit('setCompany', value);
    },

    async fetchCompanies({ commit }) {
      try {
        const xhr = await Api.fetchCompanies();
        const companies = JSON.parse(xhr.response);
        commit('fetchCompanies', companies);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
    async fetchConsumption({ commit, state }) {
      try {
        const xhr = await Api.fetchConsumption(state.initDate, state.endDate);
        const consumption = JSON.parse(xhr.response);
        commit('fetchConsumption', consumption);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
  },
  mutations: {
    setInitDate(state, value) {
      state.initDate = value;
    },
    setEndDate(state, value) {
      state.endDate = value;
    },
    setCompany(state, value) {
      state.company = value;
    },
    fetchCompanies(state, value) {
      state.companies = value;
    },
    fetchConsumption(state, value) {
      state.consumption = value;
    },
    initStorage(state) {
      state.initDate = '';
      state.endDate = '';
      state.company = '';
      state.companies = [];
      state.consumption = [];
    },

  },
};
