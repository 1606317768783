<template>
  <f7-page resizable>
    <navbar :text="$t('LeftPanel_subtitle_home')" />
    <div class="content-devx">
      <NewUserRegister :user="newUser" />
    </div>
    <div>
      <f7-row>
        <f7-col style="width: 10%; margin-left: 75%;">
          <f7-button
            name="btnSignIn"
            class="botonSecundary"
            col
            button
            button-large
            button-raised
            fill
            style="color:#FFFFFF"
            @click="back"
          >
            {{ $t("cancel") }}
          </f7-button>
        </f7-col>
        <f7-col style="width: 10%;">
          <f7-button
            name="btnSignIn"
            class="botonPrimary"
            col
            button
            button-large
            button-raised
            fill
            style="color:#FFFFFF"
            @click="saveUser"
          >
            {{ $t("save") }}
          </f7-button>
        </f7-col>
      </f7-row>
    </div>
  </f7-page>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import navbar from '../../components/navbar/NavBar.vue';
import NewUserRegister from '../../components/newUser/index.vue';

export default {
  name: 'RegisterUser',
  components: {
    NewUserRegister,
    navbar,
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapState('User', ['newUser', 'confirmPassword']),
  },
  async beforeMount() {
    this.$f7.preloader.show();
    try {
      await this.fetchProfile();
    } catch (error) {
      console.error(error);
      this.$f7.dialog.alert(this.$t(`${error}`));
    } finally {
      this.$f7.preloader.hide();
    }
  },
  methods: {
    back() {
      this.$f7.views.main.router.navigate('/orders/', { reloadCurrent: true });
    },
    async saveUser() {
      try {
        if (this.newUser.name === '') {
          this.$f7.dialog.alert(this.$t('newUser.alert.required_name'));
        } else if (this.newUser.surname === '') {
          this.$f7.dialog.alert(this.$t('newUser.alert.required_surname'));
        } else if (this.newUser.email === '') {
          this.$f7.dialog.alert(this.$t('newUser.alert.required_email'));
        } else if (this.newUser.password !== '' && this.newUser.confirmPassword !== '' && (this.newUser.password !== this.confirmPassword)) {
          this.$f7.dialog.alert(this.$t('newUser.alert.required_same_password'));
        } else {
          const profile = await this.updateProfile(this.newUser);
          this.setProfileUser(profile);
          this.$f7.views.main.router.navigate('/orders/', { reloadCurrent: true });
          this.resetNewUser();
        }
      } catch (error) {
        console.error(error);
        this.$f7.dialog.alert(this.$t(`${error}`));
      } finally {
        this.$f7.preloader.hide();
      }
    },
    ...mapActions('User', ['fetchProfile', 'updateProfile', 'resetNewUser', 'setNewUser']),
    ...mapActions('authentication', ['setProfileUser']),
  },
};
</script>

<style lang="scss">

</style>
