var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content-devx"},[_c('DxForm',{staticClass:"padding-half"},[_c('DxSimpleItem',{attrs:{"name":"company","data-field":"company","editor-options":{
        onValueChanged: _vm.onChangeCompany,
        value: _vm.selectedCompany.name,
      }}},[_c('DxLabel',{attrs:{"text":_vm.$t('Nombre compañia')}}),_vm._v(" "),_c('DxRequiredRule',{attrs:{"message":_vm.$t('Field_Required')}})],1),_vm._v(" "),_c('DxSimpleItem',{attrs:{"name":"username","data-field":"username","editor-options":{
        onValueChanged: _vm.onChangeMargaretUser,
        value: _vm.selectedCompany.credential.username,
      }}},[_c('DxLabel',{attrs:{"text":_vm.$t('Usuario Margaret')}}),_vm._v(" "),_c('DxRequiredRule',{attrs:{"message":_vm.$t('Field_Required')}})],1),_vm._v(" "),_c('DxSimpleItem',{attrs:{"name":"password","data-field":"password","editor-options":{
        onValueChanged: _vm.onChangePassword,

      }}},[_c('DxLabel',{attrs:{"text":_vm.$t('Contraseña Margaret')}}),_vm._v(" "),_c('DxRequiredRule',{attrs:{"message":_vm.$t('Field_Required')}})],1),_vm._v(" "),_c('DxSimpleItem',{attrs:{"name":"name","data-field":"name","editor-options":{
        onValueChanged: _vm.onChangeHost,
        value: _vm.selectedCompany.credential.host,
      }}},[_c('DxLabel',{attrs:{"text":_vm.$t('Host')}}),_vm._v(" "),_c('DxRequiredRule',{attrs:{"message":_vm.$t('Field_Required')}})],1)],1),_vm._v(" "),_c('div',[_vm._m(0),_vm._v(" "),_c('DxCheckBox',{staticClass:"padding",attrs:{"value":_vm.selectedCompany.status,"text":_vm.$t('ACTIVO').toUpperCase()},on:{"value-changed":_vm.OnSelectedMakeActive}})],1),_vm._v(" "),_c('input',{attrs:{"id":"fileInput","type":"file","accept":"image/*"},on:{"change":_vm.onFileSelected}}),_vm._v(" "),_c('br'),_vm._v(" "),(_vm.image)?_c('div',{staticClass:"margin-top"},[_c('img',{staticStyle:{"width":"200px"},attrs:{"src":_vm.image}})]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"margin-top"},[_c('img',{staticStyle:{"width":"200px"},attrs:{"src":_vm.selectedCompany.logo}})])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('label',{staticClass:"margin dx-field-item-label dx-field-item-label-location-left",attrs:{"for":"dx_dx-6598e3e6-1fec-d875-6768-c9bd57af04a5_password"}},[_c('span',{staticClass:"dx-field-item-label-content",staticStyle:{"width":"92px"}},[_c('span',{staticClass:"dx-field-item-label-text"},[_vm._v("Hacer Compañia Activa")])])])}]

export { render, staticRenderFns }