<template>
  <div>
    <DxDataGrid
      :data-source="dataOrders"
      :allow-column-reordering="true"
      :show-borders="true"
      :show-column-lines="true"
      :show-row-lines="false"
      :column-auto-width="true"
      :row-alternation-enabled="true"
      :word-wrap-enabled="true"
      @row-removed="callToDeleteOrder"
      @selection-changed="onSelectionChanged"
    >
      <DxSelection
        mode="single"
      />

      <DxStateStoring
        :enabled="true"
        type="localStorage"
        storage-key="DxOrders"
      />
      <DxEditing
        :allow-deleting="true"
        :confirm-delete="true"
        mode="popup"
      >
        <DxTexts
          :confirm-delete-message="$t('Confirm_Delete_Text')"
          :confirm-delete-title="$t('Confirm_Delete_Title')"
        />
      </DxEditing>
      <DxSelection mode="multiple" />
      <DxExport
        :enabled="false"
      />
      <DxPaging :page-size="10" />
      <DxPager
        :show-page-size-selector="true"
        :show-info="true"
      />
      <DxHeaderFilter :visible="true" />
      <DxSearchPanel
        :visible="true"
        :width="200"
        :placeholder="$t('Search_placeholder')"
      />
      <DxColumnFixing :enabled="true" />
      <DxColumn
        v-if="user.contact.staff"
        data-field="company_name"
        :caption="$t('OrderTable.company')"
        :allow-header-filtering="true"
        alignment="left"
      />
      <DxColumn
        data-field="customer"
        :caption="$t('OrderTable.client')"
        :allow-header-filtering="true"
        alignment="left"
      />
      <DxColumn
        data-field="order"
        :caption="$t('OrderTable.order')"
        :allow-header-filtering="false"
        alignment="left"
      />
      <DxColumn
        data-field="date_emmission"
        :caption="$t('OrderTable.date')"
        :allow-header-filtering="false"
        alignment="center"
      />
      <DxColumn
        data-field="date_deliver"
        :caption="$t('OrderTable.delivery')"
        :allow-header-filtering="false"
        alignment="center"
      />

      <DxColumn
        v-if="user.contact.staff"
        :caption="$t('OrderTable.totalValue')"
        :calculate-cell-value="calculateAmount"
        :allow-header-filtering="false"
        alignment="right"
      />

      <DxColumn
        data-field="status"
        :caption="$t('OrderTable.state')"
        alignment="center"
        :allow-header-filtering="true"
        cell-template="cellTemplate"
        :header-filter="{ dataSource: dataOrderStatus }"
      />
      <DxColumn
        type="buttons"
        :caption="$t('OrderTable.actions')"
      >
        <DxButton
          name="find"
          icon="find"
          :on-click="goToViewOrder"
        />
        <DxButton
          :element-attr="buttonAtributes"
          name="delete"
          icon="trash"
        />
      </DxColumn>
      <template #cellTemplate="{ data: dataOrders }">
        <div :class="setClassByStatus(dataOrders.data.status)">
          {{ $t(`OrderTable.status.${dataOrders.data.status}`) }}
        </div>
      </template>
    </DxDataGrid>
    <f7-row class="margin">
      <f7-col width="70" />
      <f7-col width="10">
        <DxSelectBox
          :items="listStatus"
          @value-changed="selectStatus"
        />
      </f7-col>
      <f7-col width="20">
        <f7-button
          :disabled="!statusSelected"
          class="botonPrimary"
          type="button"
          @click="statusProcess"
        >
          Cambiar Estado
        </f7-button>
      </f7-col>
    </f7-row>
  </div>
</template>

<script>
import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxSearchPanel,
  DxExport,
  // DxScrolling,
  DxColumnFixing,
  DxHeaderFilter,
  DxStateStoring,
  DxPager,
  DxSelection,
  DxButton,
  DxEditing,
  DxTexts,

} from 'devextreme-vue/data-grid';
import DxSelectBox from 'devextreme-vue/select-box';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'ComponentOrders',
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxSearchPanel,
    DxExport,
    // DxScrolling,
    DxColumnFixing,
    DxHeaderFilter,
    DxStateStoring,
    DxPager,
    DxSelection,
    DxButton,
    DxEditing,
    DxTexts,
    DxSelectBox,

  },
  props: {
    dataOrders: { type: Array, default: () => [] },
  },
  data() {
    return {
      statusSelected: '',
      dataOrderStatus: [{
        text: this.$t('OrderTable.status.acepted'),
        value: 'acepted',
      }, {
        text: this.$t('OrderTable.status.sent'),
        value: 'sent',
      },
      {
        text: this.$t('OrderTable.status.pending'),
        value: 'pending',
      },
      {
        text: this.$t('OrderTable.status.invalid'),
        value: 'invalid',
      },
      {
        text: this.$t('OrderTable.status.cancel'),
        value: 'cancel',
      }],
      buttonAtributes: { id: 'removeButton', class: 'classButton' },
    };
  },
  computed: {
    ...mapState('Order', ['listStatus']),
    ...mapState('authentication', ['user']),
  },
  methods: {
    async statusProcess() {
      try {
        this.$f7.preloader.show();
        await this.ordersProcess(this.statusSelected);
      } catch (error) {
        console.error(error);
        this.$f7.dialog.alert(this.$t(`${error}`));
      } finally {
        this.$f7.preloader.hide();
        this.$f7.views.main.router.navigate('/orders/', { reloadCurrent: true });
      }
    },
    selectStatus(e) {
      this.statusSelected = e.value;
    },
    async goToViewOrder(rowData) {
      this.setIsEdit(true);
      await this.getOrder(rowData.row.data.id);
      this.$f7.views.main.router.navigate('/createOrders/', { reloadCurrent: true });
    },
    onSelectionChanged(e) {
      const idOrders = [];
      for (const id in e.selectedRowsData) {
        if (Object.hasOwnProperty.call(e.selectedRowsData, id)) {
          idOrders[id] = e.selectedRowsData[id].id;
        }
      }
      this.setOrderSelected(idOrders);
    },
    async callToDeleteOrder(rowSelected) {
      try {
        if (rowSelected.data.status !== 'sent') {
          this.$f7.preloader.show();
          await this.deleteOrder(rowSelected.data.id);
          this.$f7.views.main.router.navigate('/orders/', { reloadCurrent: true });
        } else {
          this.$f7.dialog.alert('Los pedidos que tenga el estado enviado no se pueden eliminar.');
        }
      } catch (error) {
        console.error(error);
        this.$f7.dialog.alert(this.$t(`${error}`));
      } finally {
        this.$f7.preloader.hide();
      }
    },
    setClassByStatus(status) {
      let colorCell = '';
      switch (status) {
        case 'acepted':
          colorCell = 'Acepted';
          break;
        case 'sent':
          colorCell = 'Sent';
          break;
        case 'pending':
          colorCell = 'Pending';
          break;
        case 'invalid':
          colorCell = 'Invalid';
          break;
        case 'cancel':
          colorCell = 'Cancel';
          break;
        default:
          break;
      }
      return colorCell;
    },
    calculateAmount(rowData) {
      let sumatory = 0;
      for (const article of rowData.articles) {
        sumatory += article.unit === '' ? article.price : (article.price * article.amount);
      }
      if (!sumatory) {
        sumatory = 0;
      }
      return parseFloat(sumatory).toFixed(2);
    },
    onToolbarPreparing(e) {
      e.toolbarOptions.items.unshift({
        location: 'before',
        widget: 'dxButton',
        options: {
          icon: 'add',
          elementAttr: {
            class: 'add-task',
          },
          text: this.$t('DxDailyPlanning_text'),
          onClick: () => {
            this.setIsEdit(false);
            this.$f7.views.main.router.navigate('/createOrders/', { reloadCurrent: true });
          },
        },
      });
    },
    ...mapActions('Order', ['setIsEdit', 'ordersProcess', 'setOrderSelected', 'deleteOrder', 'getOrder']),
  },
};
</script>

<style>
.Sent, .Cancel, .Pending, .Acepted, .Invalid {
  border-radius: 2px;
  font-size: 12px;
  padding: 4px 2px;
  font-weight: 600;
  text-transform: uppercase;
  margin-left: 15px;
  margin-right: 15px;
}

.Acepted {
  color: #219653 !important;
  background: #DEFCE8 !important;
  border-width: 1px;
    border-style: solid;
    border-color: #219653;
}
.Cancel {
  color: #74838C;
  background: #F4F5F7;
  border-width: 1px;
    border-style: solid;
    border-color: #74838C;
}
.Pending {
  color: #FF9200;
  background: #fff4e5;
  border-width: 1px;
    border-style: solid;
    border-color: #FF9200;
}
.Invalid {
  color: #ec0000;
  background: rgb(255, 225, 225);
  border-width: 1px;
    border-style: solid;
    border-color: #ec0000;
}

.Sent {
  color: #23295e;
  background: #dcf1ff;
  border-width: 1px;
  border-style: solid;
  border-color: #23465E;
}
.button{
  display: flex;
  align-items:center;
  justify-content: center;
  height: 34px;
  min-width: 100px;
  overflow: hidden;
  position: relative;
  margin-right: 6px !important;
  margin-left: 6px !important;
  border-radius: 3px !important;
  color: #FAF8F2;
    }
</style>
