<!-- EDITE -->
<template>
  <f7-page
    class="main-itemsAvailabilities"
  >
    <navbar :text="`${$t('Crear nueva Compañia')}`" />

    <UpdateCompanies
      :loaded="loaded"
      :current-company="currentCompany"
      :current-margaret-user="currentMargaretUser"
      :current-host="currentHost"
      :current-company-img="currentCompanyImg"
      :current-margaret-password="currentMargaretPassword"
    />

    <div

      class="content-action-botton"
    >
      <div
        style="justify-content: flex-start; float: left;"
        class="dx-item dx-toolbar-item dx-toolbar-button"
        @click="backToCompanies()"
      >
        <div
          class="dx-item-content dx-toolbar-item-content"
        >
          <div
            class="dx-button dx-button-normal dx-button-mode-contained
              dx-widget add-task dx-button-has-icon dx-button-has-text"
            style=" background: #ffffff;
              border-color: var(--adp-gray);
              color: #838383;"
            aria-label="FICHERO"
            tabindex="0"
            role="button"
          >
            <div
              class="dx-button-content"
              style="
                  display: flex; align-items: center; justify-content:
                  center; height: 34px; min-width: 100px; overflow: hidden;
                  position: relative; margin-right: 6px !important; margin-left: 6px !important;
                  border-radius: 3px !important;
                 "
            >
              <span class="dx-button-text">CANCELAR</span>
            </div>
          </div>
        </div>
      </div>

      <div
        style="justify-content: flex-start; float: left;"
        class="dx-item dx-toolbar-item dx-toolbar-button"
        @click="updateCompany()"
      >
        <div class="dx-item-content dx-toolbar-item-content">
          <div
            class="dx-button dx-button-normal dx-button-mode-contained
          dx-widget add-task dx-button-has-icon dx-button-has-text"
            aria-label="FICHERO"
            tabindex="0"
            role="button"
          >
            <div
              class="dx-button-content"
              style="display: flex; align-items: center;
                justify-content: center; height: 34px; min-width: 100px;
                overflow: hidden; position: relative; margin-right: 6px !important;
                margin-left: 6px !important; border-radius: 3px !important;"
            >
              <span class="dx-button-text">GUARDAR</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </f7-page>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import navbar from '../../components/navbar/NavBar.vue';

import UpdateCompanies from '../../components/companies/updatecompanies/index.vue';

export default {
  name: 'ReportProvider',
  components: {
    navbar,
    // FilterCompanies,
    UpdateCompanies,

  },
  data() {
    return {
      loaded: false,
    };
  },
  computed: {
    ...mapState('Companies', ['companiesAvailabilities',
      'currentCompany',
      'currentMargaretUser',
      'currentHost',
      'currentCompanyImg',
      'currentMargaretPassword',
      'currentCompanyImg',

    ]),

  },
  async beforeMount() {
    this.$f7.preloader.show();
    try {
      await this.fetchCompanies();
      // await this.initStore();
    } catch (error) {
      console.error(error);
      this.$f7.dialog.alert(this.$t(`${error}`));
    } finally {
      this.loaded = true;
      this.$f7.preloader.hide();
    }
  },
  methods: {
    async updateCompany() {
      try {
        // await this.createCompany();
      } catch (error) {
        console.error(error);
        this.someError = true;
        this.$f7.dialog.alert(this.$t(`${error}`));
      } finally {
        if (!this.someError) {
          this.someError = false;
          this.$f7.views.main.router.navigate('/companies/', { reloadCurrent: true });
          this.$f7.dialog.alert('CORRECTO');
          await this.clearStates();
        }
      }
    },
    backToCompanies() {
      this.$f7.views.main.router.navigate('/companies/', { reloadCurrent: true });
    },

    ...mapActions('Companies', ['fetchCompanies',
      'updateCompany',
      'createCompany',
      'clearStates']),

  },
};
</script>
