// import i18next from 'i18next';
import Api from '../../services/Api';
import Helpers from '../../js/helpers';

export default {
  namespaced: true,
  state: {
    companiesAvailabilities: [],
    selectedCompany: {},
    currentCompany: '',
    currentMargaretUser: '',
    currentHost: '',
    currentCompanyImg: '',
    currentMargaretPassword: '',
    currentStatus: false,
  },
  actions: {

    async fetchCompanies({ commit }) {
      try {
        const xhr = await Api.fetchAllCompanies();
        const dataCompanies = JSON.parse(xhr.response).data;
        commit('setCompanies', dataCompanies);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
    async createCompany({ state }) {
      const company = {
        name: state.currentCompany,
        logo: state.currentCompanyImg,
        credential: {
          host: state.currentHost,
          username: state.currentMargaretUser,
          password: state.currentMargaretPassword,
        },
        status: state.currentStatus,
        settings: {},
      };
      try {
        await Api.createCompany(company);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
    async getCompanyId({ commit }, CompanyId) {
      try {
        const xhr = await Api.getCompany(CompanyId);
        const company = JSON.parse(xhr.response);
        commit('setSelectedCompany', company);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
    async updateCompany({ commit, state }, companyId) {
      try {
        const company = {
          name: state.currentCompany,
          logo: state.currentCompanyImg,
          credential: {
            host: state.currentHost,
            username: state.currentMargaretUser,
            password: state.currentMargaretPassword,
          },
          status: state.currentStatus,
        };
        const xhr = await Api.editCompany(companyId, company);
        const updateCompany = JSON.parse(xhr.response);
        commit('setCompanies', updateCompany);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },

    setCurrentCompany(context, value) {
      context.commit('setCurrentCompany', value);
    },
    setCurrentMargaretUser(context, value) {
      context.commit('setCurrentMargaretUser', value);
    },
    setCurrentMargaretPassword(context, value) {
      context.commit('setCurrentMargaretPassword', value);
    },
    setCurrentCompanyImg(context, value) {
      context.commit('setCurrentCompanyImg', value);
    },
    setCurrentHost(context, value) {
      context.commit('setCurrentHost', value);
    },
    setSelectedCompany(context, value) {
      context.commit('setSelectedCompany', value);
    },
    setCurrentStatus(context, value) {
      context.commit('setCurrentStatus', value);
    },
    clearStates(context, value) {
      context.commit('clearStates', value);
    },
  },
  mutations: {
    setCompanies(state, value) {
      state.companiesAvailabilities = value;
    },
    setCurrentCompany(state, value) {
      state.currentCompany = value;
    },
    setCurrentMargaretUser(state, value) {
      state.currentMargaretUser = value;
    },
    setCurrentMargaretPassword(state, value) {
      state.currentMargaretPassword = value;
    },
    setCurrentCompanyImg(state, value) {
      state.currentCompanyImg = value;
    },
    setCurrentHost(state, value) {
      state.currentHost = value;
    },
    setCurrentStatus(state, value) {
      state.currentStatus = value;
    },
    setSelectedCompany(state, value) {
      state.selectedCompany = value;
    },
    clearStates(state) {
      state.currentCompany = '';
      state.currentMargaretUser = '';
      state.currentHost = '';
      state.currentCompanyImg = '';
      state.currentMargaretPassword = '';
      state.currentStatus = false;
    },

  },
};
