<template>
  <f7-page
    resizable
    @page:beforein="beforein"
  >
    <navbar text="Home" />
    <f7-block>
      <h1 class="welcome-message">
        Bienvenido a la Automatización de Pedidos
      </h1>
      <p class="welcome-text">
        Esta es tu página de inicio donde podrás gestionar tus pedidos de manera eficiente.
      </p>
    </f7-block>
  </f7-page>
</template>

<script>

import { mapState, mapGetters } from 'vuex';
import navbar from '../components/navbar/NavBar.vue';

export default {
  name: 'PageDashboard',
  components: { navbar },
  data() {
    return {
      loaded: false,
      keyName: 'orders',
    };
  },
  computed: {
    ...mapGetters('CalendarInitEndDate', ['actualFilter']),
    ...mapState('Order', ['orders']),
  },
  methods: {
    async beforein() {
      // this.$f7.preloader.show();

      // this.$f7.views.main.router.navigate('/orders/', { reloadCurrent: true });
    },
  },

};
</script>

<style lang="scss">
.welcome-message {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #1D222A; /* Utiliza el color base del tema oscuro */
}

.welcome-text {
  font-size: 16px;
  margin-bottom: 20px;
  color: #1D222A; /* Utiliza el color base del tema oscuro */
}

.custom-card {
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.custom-card f7-card-title {
  font-size: 20px;
  font-weight: 500;
  color: #6200EE; /* Utiliza el color primario del tema claro */
}

.custom-card p {
  font-size: 14px;
  color: #333;
}
</style>
