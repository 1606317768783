<template>
  <f7-page resizable>
    <navbar
      v-if="!isEdit"
      :text="`${$t('navbar_title_createSaleAccount')}`"
    />
    <navbar
      v-else
      :text="`${$t('navbar_title_viewSaleAccount')}`"
    />
    <f7-block>
      <div v-if="!isUploaded && !isEdit">
        <UpFile />
      </div>
      <div v-else>
        <ViewSaleAccount />
      </div>
      <div>
        <div v-if="isEdit || isUploaded">
          <f7-row style="justify-content: flex-start !important;">
            <f7-col style="width: 10%; margin: 15px;">
              <f7-button
                name="btnSignIn"
                class="botonSecundary"
                col
                button
                button-large
                button-raised
                fill
                style="color:#FFFFFF;"
                @click="back"
              >
                {{ $t('cancel') }}
              </f7-button>
            </f7-col>
            <f7-col
              v-if="isEdit && newSaleAccount.status !== 'sent'"
              style="width: 10%; margin: 15px;"
            >
              <f7-button
                name="btnSignIn"
                class="botonPrimary"
                col
                button
                button-large
                button-raised
                fill
                style="color:#FFFFFF"
                @click="saveSaleAccount"
              >
                {{ $t('save') }}
              </f7-button>
            </f7-col>
            <f7-col
              v-else-if="!isEdit && newSaleAccount.status !== 'sent'"
              style="width: 10%; margin: 15px;"
            >
              <f7-button
                name="btnSignIn"
                class="botonPrimary"
                col
                button
                button-large
                button-raised
                fill
                style="color:#FFFFFF"
                @click="setSaleAccount"
              >
                {{ $t('save') }}
              </f7-button>
            </f7-col>
          </f7-row>
        </div>
      </div>
    </f7-block>
  </f7-page>
</template>

<script>

import { mapState, mapActions } from 'vuex';
import navbar from '../../../components/navbar/NavBar.vue';
import UpFile from '../../../components/saleAccounts/create/load/index.vue';
import ViewSaleAccount from '../../../components/saleAccounts/view/index.vue';

export default {
  name: 'CreateSaleAccounts',
  components: {
    navbar,
    ViewSaleAccount,
    UpFile,
  },
  data() {
    return {
      dataArticles: [],
    };
  },
  computed: {
    ...mapState('SaleAccount', ['isEdit', 'saleAccounts', 'newSaleAccount', 'isUploaded']),
  },
  methods: {
    back() {
      this.setIsUploaded(false);
      this.setIsEdit(false);
      this.resetNewSaleAccount({});
      this.$f7.views.main.router.navigate('/sale-accounts/', { reloadCurrent: true });
    },
    async saveSaleAccount() {
      try {
        await this.updateSaleAccount(this.newSaleAccount);
        this.$f7.views.main.router.navigate('/sale-accounts/', { reloadCurrent: true });
        this.resetNewSaleAccount();
        this.setIsEdit(false);
        this.setIsUploaded(false);
      } catch (error) {
        console.error(error);
        this.$f7.dialog.alert(this.$t(`${error}`));
      }
    },
    async setSaleAccount() {
      try {
        await this.postSaleAccount(this.newSaleAccount);
        this.$f7.views.main.router.navigate('/sale-accounts/', { reloadCurrent: true });
        this.setIsEdit(false);
        this.setIsUploaded(false);
      } catch (error) {
        console.error(error);
        this.$f7.dialog.alert(this.$t(`${error}`));
      }
    },
    ...mapActions('SaleAccount', ['updateSaleAccount', 'postSaleAccount', 'resetNewSaleAccount', 'setIsUploaded',
      'setIsEdit']),
  },
};
</script>

<style lang="scss">

</style>
