<template>
  <div class="fp-section">
    <DxDataGrid
      :data-source="users"
      :allow-column-reordering="true"
      :show-borders="true"
      :show-column-lines="true"
      :show-row-lines="false"
      :row-alternation-enabled="true"
      @exporting="onExporting"
      @toolbar-preparing="onToolbarPreparing($event)"
    >
      <DxSelection
        mode="single"
      />
      <DxStateStoring
        :enabled="true"
        type="localStorage"
        storage-key="UsersDatagrid"
      />
      <DxHeaderFilter :visible="true" />
      <DxExport
        :enabled="true"
      />
      <DxPaging :page-size="10" />
      <DxPager
        :show-page-size-selector="true"
        :allowed-page-sizes="pageSizes"
        :show-info="true"
      />
      <DxSearchPanel
        :visible="true"
        :width="200"
        :highlight-case-sensitive="true"
        :placeholder="$t('Search_placeholder')"
      />
      <DxEditing
        :allow-updating="true"
      />
      <DxColumn
        v-if="user.contact.staff"
        data-field="company_name"
        name="date"
        :caption="$t('OrderTable.company')"
        :allow-header-filtering="false"
      />
      <DxColumn
        data-field="name"
        name="date"
        :caption="$t('newUser.newUser_name')"
        :allow-header-filtering="false"
      />
      <DxColumn
        data-field="surname"
        name="date"
        :caption="$t('newUser.newUser_surname')"
        :allow-header-filtering="false"
      />
      <DxColumn
        data-field="email"
        name="date"
        :caption="$t('newUser.newUser_email')"
        :allow-header-filtering="false"
      />

      <DxColumn
        type="buttons"
        :fixed="true"
        fixed-position="right"
      >
        <DxButton
          name="edit"
          icon="edit"
          :on-click="editUser"
        />
        <DxButton
          name="remove"
          icon="trash"
          :on-click="removeUser"
        />
      </DxColumn>
    </DxDataGrid>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxSearchPanel,
  DxExport,
  DxHeaderFilter,
  DxStateStoring,
  DxPager,
  DxSelection,
  DxButton,
  DxEditing,
} from 'devextreme-vue/data-grid';
import { exportDataGrid } from 'devextreme/excel_exporter';
import ExcelJS from 'exceljs';
import saveAs from 'file-saver';
import Api from '../../../services/Api';

export default {
  name: 'UsersDatagrid',
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxSearchPanel,
    DxExport,
    DxHeaderFilter,
    DxStateStoring,
    DxPager,
    DxSelection,
    DxButton,
    DxEditing,
  },
  props: {
    users: {
      type: Array,
      default: () => [],
    },
  },
  computed: {

    ...mapState('authentication', ['user']),
  },
  data() {
    return {
      pageSizes: [5, 10, 20],
    };
  },
  methods: {
    removeUser(e) {
      // this.updateCurrentUserProperty({ property: 'id', newValue: e.row.data.id });
      this.$f7.dialog.confirm(this.$t('Configuration.sure_remove_user'), () => {
        this.removeUserFromApi(e.row.data.id);
      });
    },
    async removeUserFromApi(userDeleteId) {
      this.$f7.preloader.show();
      try {
        await Api.deleteUser(userDeleteId);
      } catch (error) {
        console.error(error);
        this.$f7.dialog.alert(this.$t(`${error}`));
      } finally {
        this.loaded = true;
        this.$f7.preloader.hide();
        this.$f7.dialog.alert('Great!');
        this.$f7.views.main.router.navigate('/users/', { reloadCurrent: true });
      }
    },
    async editUser(e) {
      // this.updateCurrentUserProperty({ property: 'id', newValue: e.row.data.id });
      try {
        await this.getUserById(e.row.data.id);
      } catch (error) {
        console.error(error);
        this.$f7.dialog.alert(this.$t(`${error}`));
      } finally {
        this.loaded = true;
        this.$f7.preloader.hide();
      }

      this.$f7.views.main.router.navigate('/editUser/', { reloadCurrent: true });
    },
    // EXPORT EXCEL
    onExporting(e) {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('Users');
      exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
      }).then(() => {
        workbook.xlsx.writeBuffer()
          .then((buffer) => {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Users.xlsx');
          });
      });
      e.cancel = true;
    },
    onToolbarPreparing(e) {
      e.toolbarOptions.items.unshift({
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'add',
          elementAttr: {
            class: 'add-task',
          },
          onClick: () => {
            this.$f7.views.main.router.navigate('/createUser/', { reloadCurrent: true });
          },
        },
      });
    },
    ...mapActions('UserData', ['updateCurrentUserProperty', 'getUserById', 'deleteUser']),
  },
};
</script>
<style>
@import './UsersDatagrid.styles.scss';
</style>
