<!-- CREAR -->
<template>
  <div class="content-devx">
    <DxForm
      id="userForm"
    >
      <DxGroupItem
        css-class="content-combo"
        :col-count="1"
      >
        <DxGroupItem>
          <DxSimpleItem
            name="company"
            data-field="company"
            :editor-options="{
              onValueChanged: onChangeCompany,
              placeholder: $t('Compañia ...'),
            }"
          >
            <DxLabel :text="$t('Nombre compañia')" />
            <DxRequiredRule :message="$t('Field_Required')" />
          </DxSimpleItem>

          <DxSimpleItem
            name="username"
            data-field="username"
            :editor-options="{
              onValueChanged: onChangeMargaretUser,
              placeholder: $t('Usuario ...'),
            }"
          >
            <DxLabel :text="$t('Usuario Margaret')" />
            <DxRequiredRule :message="$t('Field_Required')" />
          </DxSimpleItem>
          <DxSimpleItem
            name="password"
            data-field="password"
            :editor-options="{
              onValueChanged: onChangePassword,
              placeholder: $t('Contraseña ...'),
            }"
          >
            <DxLabel :text="$t('Contraseña Margaret')" />
            <DxRequiredRule :message="$t('Field_Required')" />
          </DxSimpleItem>

          <DxSimpleItem
            name="name"
            data-field="name"
            :editor-options="{
              onValueChanged: onChangeHost,
              placeholder: $t('Host ...'),
            }"
          >
            <DxLabel :text="$t('Host')" />
            <DxRequiredRule :message="$t('Field_Required')" />
          </DxSimpleItem>
        </DxGroupItem>
      </Dxgroupitem>
    </DxForm>

    <div>
      <label
        class="margin dx-field-item-label dx-field-item-label-location-left"
        for="dx_dx-6598e3e6-1fec-d875-6768-c9bd57af04a5_password"
      >
        <span
          class="dx-field-item-label-content"
          style="width: 92px;"
        >
          <span class="dx-field-item-label-text">Hacer Compañia Activa</span>
        </span>
      </label>
      <DxCheckBox
        :value="status"
        :text="$t('ACTIVO').toUpperCase()"
        class="padding"
        @value-changed="OnSelectedMakeActive"
      />
    </div>
    <input
      id="fileInput"
      type="file"
      accept="image/*"
      @change="onFileSelected"
    >
    <br>
    <div
      v-if="image"
      style=""
      class="margin-top"
    >
      <img
        style="width:200px"
        :src="image"
      >
    </div>
  </div>
</template>
<script>

import { mapActions, mapState } from 'vuex';
import {
  DxForm,
  DxSimpleItem,
  DxGroupItem,
  DxRequiredRule,
  DxLabel,
} from 'devextreme-vue/form';
import DxCheckBox from 'devextreme-vue/check-box';

export default {
  name: 'UserForm',
  components: {
    DxForm,
    DxSimpleItem,
    DxGroupItem,
    DxRequiredRule,
    DxLabel,
    DxCheckBox,

  },
  props: {
    currentCompany: {
      type: String,
      default: () => '',
    },
    currentMargaretUser: {
      type: String,
      default: () => '',
    },
    currentHost: {
      type: String,
      default: () => '',
    },
    currentCompanyImg: {
      type: String,
      default: () => '',
    },
    currentMargaretPassword: {
      type: String,
      default: () => '',
    },
    currentStatus: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      status: false,
      image: '',
      someError: false,
    };
  },
  beforeMount() {

  },
  computed: {
    ...mapState('authentication', ['user']),
  },
  methods: {

    onFileSelected(event) {
      const reader = new FileReader();
      const newFile = event.target.files[0];

      reader.onload = (e) => {
        const selectedFile = e.target.result;
        this.image = selectedFile;
        this.setCurrentCompanyImg(selectedFile);
        // this.setNameAttachment('metro');
        // this.setAttachment(selectedFile);
        // this.postAnalaysis();
      };
      reader.onerror = (error) => {
        this.$f7.dialog.alert(`${this.$t('errorinformation.reader')} ${error}`);
      };
      reader.readAsDataURL(newFile);
    },

    onChangeCompany(e) {
      this.setCurrentCompany(e.value);
    },
    onChangeLogo(e) {
      this.setCurrentCompanyImg(e.value);
    },
    onChangeMargaretUser(e) {
      this.setCurrentMargaretUser(e.value);
    },
    onChangeHost(e) {
      this.setCurrentHost(e.value);
    },
    onChangePassword(e) {
      this.setCurrentMargaretPassword(e.value);
    },
    OnSelectedMakeActive(e) {
      this.setCurrentStatus(e.value);
    },
    ...mapActions('Companies', ['setCurrentCompany',
      'setCurrentMargaretUser',
      'setCurrentCompanyImg',
      'setCurrentHost',
      'setCurrentStatus',
      'setCurrentMargaretPassword',
      'createCompany']),
  },
};
</script>

<style lang="scss" scoped>

@import './Createcompanies.styles.scss';
</style>
