<template>
  <div>
    <DxForm
      id="costForm"
    >
      <DxGroupItem
        css-class="content-combo"
      >
        <DxSimpleItem
          name="name"
          :data-field="$t('newUser.newUser_name')"
          :editor-options="{
            value: newUser.name,
            onValueChanged: onChangeName,
          }"
        >
          <DxRequiredRule :message="$t('newUser.DxCostForm_Id_required')" />
        </DxSimpleItem>
        <DxSimpleItem
          name="surname"
          :data-field="$t('newUser.newUser_surname')"
          :editor-options="{
            value: newUser.surname,
            onValueChanged: onChangeSurname,
          }"
        >
          <DxRequiredRule :message="$t('newUser.DxCostForm_Id_required')" />
        </DxSimpleItem>
        <DxSimpleItem
          name="email"
          :data-field="$t('newUser.newUser_email')"
          :editor-options="{
            value: newUser.email,
            onValueChanged: onChangeEmail,
          }"
        >
          <DxRequiredRule :message="$t('newUser.DxCostForm_Id_required')" />
        </DxSimpleItem>
        <DxSimpleItem
          name="company"
          :data-field="$t('newUser.company')"
          :editor-options="{
            value: newUser.company_name,
            onValueChanged: onChangeCompany,
          }"
        >
          <DxRequiredRule :message="$t('newUser.DxCostForm_Id_required')" />
        </DxSimpleItem>
        <DxSimpleItem
          name="password"
          :data-field="$t('newUser.newUser_password')"
          :editor-options="{
            value: newUser.password,
            onValueChanged: onChangePassword,
            mode: 'password',
          }"
        />
        <DxSimpleItem
          name="confirmPassword"
          :data-field="$t('newUser.newUser_confirmPassword')"
          :editor-options="{
            value: newUser.confirmPassword,
            onValueChanged: onChangeConfirmPassword,
            mode: 'password',
          }"
        />
      </Dxgroupitem>
    </DxForm>
  </div>
</template>
<script>

import { mapState, mapActions } from 'vuex';
import {
  DxForm,
  DxSimpleItem,
  DxGroupItem,
  DxRequiredRule,
} from 'devextreme-vue/form';

export default {
  name: 'NewUser',
  components: {
    DxForm,
    DxSimpleItem,
    DxGroupItem,
    DxRequiredRule,
  },
  props: {
    editForm: { type: Boolean, default: false },
    user: { type: Object, default: () => {} },
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapState('User', ['isNewUser', 'newUser']),
  },
  methods: {
    back() {
      this.setIsNewUser(false);
      this.resetNewUser();
    },
    onChangeName(e) {
      this.setName(e.value);
    },
    onChangeProvince(e) {
      this.setProvince(e.value);
    },
    onChangeTypeInscription(e) {
      this.setTypeInscription(e.value);
    },
    onChangeEmail(e) {
      this.setEmail(e.value);
    },
    onChangePassword(e) {
      this.setPassword(e.value);
    },
    onChangeSurname(e) {
      this.setSurname(e.value);
    },
    onChangeConfirmPassword(e) {
      this.setConfirmPassword(e.value);
    },
    onChangeConfirmEmail(e) {
      this.setConfirmEmail(e.value);
    },
    onChangeCompany(e) {
      this.setCompany(e.value);
    },
    ...mapActions('User', ['setName', 'setProvince', 'setIsNewUser', 'setTypeInscription', 'setEmail', 'setPassword',
      'setSurname', 'setConfirmPassword', 'setCompany', 'resetNewUser', 'setConfirmEmail']),
  },
};
</script>

<style lang="scss" scope>
    .content-devx {
        width: auto;
    }
  .content-combo{
    .dx-item{
      .dx-item-content{
        label{
          min-width: 160px;
        }
      }
    }
  }
  .botonPrimary {
      width: 100%;
  }
  .botonSecundary {
      width: 100%;
  }
</style>
