// import i18next from 'i18next';
import Api from '../../services/Api';
import Helpers from '../../js/helpers';

export default {
  namespaced: true,
  state: {
    orderSelected: [],
    OrderUpload: {},
    filterdate: '',
    newOrder: {},
    isEdit: false,
    orders: [],
    listStatus: ['pending', 'cancel', 'acepted'],
    isUploaded: false,
  },
  actions: {
    setClient(context, value) {
      context.commit('setClient', value);
    },
    setDelivery(context, value) {
      context.commit('setDelivery', value);
    },
    setOrderSelected(context, value) {
      context.commit('setOrderSelected', value);
    },
    setIssued(context, value) {
      context.commit('setIssued', value);
    },
    setOrder(context, value) {
      context.commit('setOrder', value);
    },
    setIsEdit(context, value) {
      context.commit('setIsEdit', value);
    },
    setAttachment(context, value) {
      context.commit('setAttachment', value);
    },
    setNameAttachment(context, value) {
      context.commit('setNameAttachment', value);
    },
    setDirection(context, value) {
      context.commit('setDirection', value);
    },
    setListStatus(context, value) {
      context.commit('setListStatus', value);
    },
    setIsUploaded(context, value) {
      context.commit('setIsUploaded', value);
    },
    async deleteOrder(context, idOrder) {
      try {
        await Api.deleteOrder(idOrder);
      } catch (error) {
        // Error controlado en el componente.
      }
    },
    async fetchAllOrders({ commit, state }, date) {
      try {
        const xhr = await Api.fetchOrders(date.init, date.end, state.filterdate);
        if (xhr.status !== 200) {
          commit('setOrders', []);
        } else {
          commit('setOrders', JSON.parse(xhr.response).data);
        }
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
    async getOrder({ commit }, idOrder) {
      try {
        const xhr = await Api.getOrder(idOrder);
        const dataOrders = JSON.parse(xhr.response);
        commit('fillViewOrder', dataOrders);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
    async updateOrder({ commit }, data) {
      try {
        await Api.updateOrder(data.id, data);
        commit('resetNewOrder');
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
    async uploadOrder({ commit }, data) {
      try {
        const xhr = await Api.uploadOrder(data);
        const dataUploaded = JSON.parse(xhr.response);
        commit('fillAfterUpload', dataUploaded);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
    async ordersProcess({ state }, data) {
      try {
        await Api.ordersProcess(state.orderSelected, data);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
    async postOrder({ state }) {
      try {
        await Api.postOrder(state.newOrder);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
    resetNewOrder(context, value) {
      context.commit('resetNewOrder', value);
    },
  },
  mutations: {
    setClient(state, value) {
      state.newOrder.customer = value;
    },
    setDelivery(state, value) {
      state.newOrder.date_deliver = value;
    },
    setIssued(state, value) {
      state.newOrder.date_emmission = value;
    },
    setOrder(state, value) {
      state.newOrder.order = value;
    },
    setIsEdit(state, value) {
      state.isEdit = value;
    },
    setOrders(state, value) {
      state.orders = value;
      state.filterdate = '';
    },
    fillViewOrder(state, value) {
      state.newOrder = value;
    },
    setAttachment(state, value) {
      state.newOrder.file = value;
    },
    setNameAttachment(state, value) {
      state.newOrder.filename = value;
    },
    resetNewOrder(state) {
      state.newOrder = {};
    },
    fillAfterUpload(state, value) {
      state.filterdate = value[0].date_emmission;
    },
    setDirection(state, value) {
      state.newOrder.destiny = value;
    },
    setListStatus(state, value) {
      state.newOrder.status = value;
    },
    setIsUploaded(state, value) {
      state.isUploaded = value;
    },
    setOrderSelected(state, value) {
      state.orderSelected = value;
    },

  },
};
