<template>
  <f7-page>
    <navbar :text="`${$t('LeftPanel_subtitle_user')}`" />
    <div class="content-devx">
      <UsersDatagrid
        v-if="loaded"
        :users="users"
      />
    </div>
  </f7-page>
</template>

<script>

import { mapState, mapActions } from 'vuex';
import navbar from '../../components/navbar/NavBar.vue';
import UsersDatagrid from '../../components/users/usersDatagrid/index.vue';

export default {
  name: 'Users',
  components: {
    UsersDatagrid,
    navbar,
  },
  data() {
    return {
      loaded: false,
    };
  },
  computed: {
    ...mapState('UserData', ['users']),
  },
  async beforeMount() {
    this.$f7.preloader.show();
    try {
      await this.fetchUsers();
    } catch (error) {
      console.error(error);
      this.$f7.dialog.alert(this.$t(`${error}`));
    } finally {
      this.loaded = true;
      this.$f7.preloader.hide();
    }
  },
  methods: {
    ...mapActions('UserData', ['fetchUsers', 'resetCurrentUser']),
  },
};
</script>
