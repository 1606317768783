<template>
  <div>
    <DxDataGrid
      :data-source="dataSaleAccounts"
      :allow-column-reordering="true"
      :show-borders="true"
      :show-column-lines="true"
      :show-row-lines="false"
      :column-auto-width="true"
      :row-alternation-enabled="true"
      :word-wrap-enabled="true"
      @row-removed="callToDeleteSaleAccount"
      @selection-changed="onSelectionChanged"
    >
      <DxSelection
        mode="single"
      />

      <DxStateStoring
        :enabled="true"
        type="localStorage"
        storage-key="DxSaleAccounts"
      />
      <DxEditing
        :allow-deleting="true"
        :confirm-delete="true"
        mode="popup"
      >
        <DxTexts
          :confirm-delete-message="$t('Confirm_Delete_Text')"
          :confirm-delete-title="$t('Confirm_Delete_Title')"
        />
      </DxEditing>
      <DxSelection mode="multiple" />
      <DxExport
        :enabled="false"
      />
      <DxPaging :page-size="10" />
      <DxPager
        :show-page-size-selector="true"
        :show-info="true"
      />
      <DxHeaderFilter :visible="true" />
      <DxSearchPanel
        :visible="true"
        :width="200"
        :placeholder="$t('Search_placeholder')"
      />
      <DxColumnFixing :enabled="true" />
      <DxColumn
        v-if="user.contact.staff"
        data-field="company_name"
        :caption="$t('SaleAccountTable.company')"
        :allow-header-filtering="true"
        alignment="left"
      />
      <DxColumn
        data-field="customer"
        :caption="$t('SaleAccountTable.client')"
        :allow-header-filtering="true"
        alignment="left"
      />
      <DxColumn
        data-field="order"
        :caption="$t('SaleAccountTable.order')"
        :allow-header-filtering="false"
        alignment="left"
      />
      <DxColumn
        data-field="date_emmission"
        :caption="$t('SaleAccountTable.date')"
        :allow-header-filtering="false"
        alignment="center"
      />
      <DxColumn
        data-field="departure_date"
        :caption="$t('SaleAccountTable.departure')"
        :allow-header-filtering="false"
        alignment="center"
      />

      <DxColumn
        v-if="user.contact.staff"
        :caption="$t('SaleAccountTable.totalValue')"
        :calculate-cell-value="calculateAmount"
        :allow-header-filtering="false"
        alignment="right"
      />

      <DxColumn
        data-field="status"
        :caption="$t('SaleAccountTable.state')"
        alignment="center"
        :allow-header-filtering="true"
        cell-template="cellTemplate"
        :header-filter="{ dataSource: dataSaleAccountStatus }"
      />
      <DxColumn
        type="buttons"
        :caption="$t('SaleAccountTable.actions')"
      >
        <DxButton
          name="find"
          icon="find"
          :on-click="goToViewSaleAccount"
        />
        <DxButton
          :element-attr="buttonAttributes"
          name="delete"
          icon="trash"
        />
      </DxColumn>
      <template #cellTemplate="{ data: dataSaleAccounts }">
        <div :class="setClassByStatus(dataSaleAccounts.data.status)">
          {{ $t(`SaleAccountTable.status.${dataSaleAccounts.data.status}`) }}
        </div>
      </template>
    </DxDataGrid>
    <f7-row class="margin">
      <f7-col width="70" />
      <f7-col width="10">
        <DxSelectBox
          :items="listStatus"
          @value-changed="selectStatus"
        />
      </f7-col>
      <f7-col width="20">
        <f7-button
          :disabled="!statusSelected"
          class="botonPrimary"
          type="button"
          @click="statusProcess"
        >
          Cambiar Estado
        </f7-button>
      </f7-col>
    </f7-row>
  </div>
</template>

<script>
import {
  DxDataGrid,
  DxColumn,
  DxPaging,
  DxSearchPanel,
  DxExport,
  // DxScrolling,
  DxColumnFixing,
  DxHeaderFilter,
  DxStateStoring,
  DxPager,
  DxSelection,
  DxButton,
  DxEditing,
  DxTexts,

} from 'devextreme-vue/data-grid';
import DxSelectBox from 'devextreme-vue/select-box';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'ComponentSaleAccounts',
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxSearchPanel,
    DxExport,
    // DxScrolling,
    DxColumnFixing,
    DxHeaderFilter,
    DxStateStoring,
    DxPager,
    DxSelection,
    DxButton,
    DxEditing,
    DxTexts,
    DxSelectBox,

  },
  props: {
    dataSaleAccounts: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      statusSelected: '',
      dataSaleAccountStatus: [{
        text: this.$t('SaleAccountTable.status.acepted'),
        value: 'acepted',
      }, {
        text: this.$t('SaleAccountTable.status.sent'),
        value: 'sent',
      },
      {
        text: this.$t('SaleAccountTable.status.pending'),
        value: 'pending',
      },
      {
        text: this.$t('SaleAccountTable.status.invalid'),
        value: 'invalid',
      },
      {
        text: this.$t('SaleAccountTable.status.cancel'),
        value: 'cancel',
      }],
      buttonAttributes: {
        id: 'removeButton',
        class: 'classButton',
      },
    };
  },
  computed: {
    ...mapState('SaleAccount', ['listStatus']),
    ...mapState('authentication', ['user']),
  },
  methods: {
    async statusProcess() {
      try {
        this.$f7.preloader.show();
        await this.saleAccountsProcess(this.statusSelected);
      } catch (error) {
        console.error(error);
        this.$f7.dialog.alert(this.$t(`${error}`));
      } finally {
        this.$f7.preloader.hide();
        this.$f7.views.main.router.navigate('/sale-accounts/', { reloadCurrent: true });
      }
    },
    selectStatus(e) {
      this.statusSelected = e.value;
    },
    async goToViewSaleAccount(rowData) {
      this.setIsEdit(true);
      await this.getSaleAccount(rowData.row.data.id);
      this.$f7.views.main.router.navigate('/create-sale-accounts/', { reloadCurrent: true });
    },
    onSelectionChanged(e) {
      const idSaleAccounts = [];
      for (const id in e.selectedRowsData) {
        if (Object.hasOwnProperty.call(e.selectedRowsData, id)) {
          idSaleAccounts[id] = e.selectedRowsData[id].id;
        }
      }
      this.setSaleAccountSelected(idSaleAccounts);
    },
    async callToDeleteSaleAccount(rowSelected) {
      try {
        if (rowSelected.data.status !== 'sent') {
          this.$f7.preloader.show();
          await this.deleteSaleAccount(rowSelected.data.id);
          this.$f7.views.main.router.navigate('/sale-accounts/', { reloadCurrent: true });
        } else {
          this.$f7.dialog.alert('Los pedidos que tenga el estado enviado no se pueden eliminar.');
        }
      } catch (error) {
        console.error(error);
        this.$f7.dialog.alert(this.$t(`${error}`));
      } finally {
        this.$f7.preloader.hide();
      }
    },
    setClassByStatus(status) {
      let colorCell = '';
      switch (status) {
        case 'acepted':
          colorCell = 'Acepted';
          break;
        case 'sent':
          colorCell = 'Sent';
          break;
        case 'pending':
          colorCell = 'Pending';
          break;
        case 'invalid':
          colorCell = 'Invalid';
          break;
        case 'cancel':
          colorCell = 'Cancel';
          break;
        default:
          break;
      }
      return colorCell;
    },
    calculateAmount(rowData) {
      let sumatory = 0;
      if (rowData.items === null) {
        rowData.items = [];
      }
      for (const article of rowData.items) {
        sumatory += article.bill_amount;
      }
      if (!sumatory) {
        sumatory = 0;
      }
      return parseFloat(sumatory)
        .toFixed(2);
    },
    onToolbarPreparing(e) {
      e.toolbarOptions.items.unshift({
        location: 'before',
        widget: 'dxButton',
        options: {
          icon: 'add',
          elementAttr: {
            class: 'add-task',
          },
          text: this.$t('DxDailyPlanning_text'),
          onClick: () => {
            this.setIsEdit(false);
            this.$f7.views.main.router.navigate('/create-sale-accounts/', { reloadCurrent: true });
          },
        },
      });
    },
    ...mapActions('SaleAccount', ['setIsEdit', 'saleAccountsProcess', 'setSaleAccountSelected', 'deleteSaleAccount', 'getSaleAccount']),
  },
};
</script>

<style>
.Sent, .Cancel, .Pending, .Acepted, .Invalid {
  border-radius: 2px;
  font-size: 12px;
  padding: 4px 2px;
  font-weight: 600;
  text-transform: uppercase;
  margin-left: 15px;
  margin-right: 15px;
}

.Acepted {
  color: #219653 !important;
  background: #DEFCE8 !important;
  border-width: 1px;
  border-style: solid;
  border-color: #219653;
}

.Cancel {
  color: #74838C;
  background: #F4F5F7;
  border-width: 1px;
  border-style: solid;
  border-color: #74838C;
}

.Pending {
  color: #FF9200;
  background: #fff4e5;
  border-width: 1px;
  border-style: solid;
  border-color: #FF9200;
}

.Invalid {
  color: #ec0000;
  background: rgb(255, 225, 225);
  border-width: 1px;
  border-style: solid;
  border-color: #ec0000;
}

.Sent {
  color: #23295e;
  background: #dcf1ff;
  border-width: 1px;
  border-style: solid;
  border-color: #23465E;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 34px;
  min-width: 100px;
  overflow: hidden;
  position: relative;
  margin-right: 6px !important;
  margin-left: 6px !important;
  border-radius: 3px !important;
  color: #FAF8F2;
}
</style>
