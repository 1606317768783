<template>
  <f7-page
    class="main-itemsAvailabilities"
  >
    <navbar :text="`${$t('Gestión de Compañias')}`" />

    <TableCompanies
      :companies-availabilities="companiesAvailabilities"
      :loaded="loaded"
    />
  </f7-page>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import navbar from '../../components/navbar/NavBar.vue';
// import FilterCompanies from '../../components/companies/filtercompanies/index.vue';
import TableCompanies from '../../components/companies/tablecompanies/index.vue';

export default {
  name: 'ReportProvider',
  components: {
    navbar,
    // FilterCompanies,
    TableCompanies,

  },
  data() {
    return {
      loaded: false,
    };
  },
  computed: {
    ...mapState('Companies', ['companiesAvailabilities',
    ]),

  },
  async beforeMount() {
    this.$f7.preloader.show();
    try {
      await this.fetchCompanies();
      // await this.initStore();
    } catch (error) {
      console.error(error);
      this.$f7.dialog.alert(this.$t(`${error}`));
    } finally {
      this.loaded = true;
      this.$f7.preloader.hide();
    }
  },
  methods: {
    ...mapActions('Companies', ['fetchCompanies']),

  },
};
</script>
