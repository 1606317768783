// Others
import NotFoundPage from '../pages/404.vue';

// Automatización de pedidos.
import CreateOrders from '../pages/orders/createOrder/createOrder.vue';
import CreateSaleAccounts from '../pages/saleAccounts/create/create.vue';
import Orders from '../pages/orders/home.vue';
import Dashboard from '../pages/dashboard.vue';
import SaleAccounts from '../pages/saleAccounts/home.vue';
import NewUserRegister from '../pages/newUser/newUser.vue';
import Consumption from '../pages/consumption/consumption.vue';
import ConsumptionSaleAccounts from '../pages/consumptionSaleAccounts/consumption.vue';
import Users from '../pages/users/users.vue';
import CreateUser from '../pages/users/CreateUser/createUser.vue';
import EditUser from '../pages/users/editUser.vue';
import ViewUser from '../pages/users/viewUser.vue';
import Companies from '../pages/companies/companymanagement.vue';
import EditCompany from '../pages/companies/companyupdate.vue';
import CreateCompany from '../pages/companies/companycreate.vue';

const routes = [
  // Default
  {
    path: '/',
    component: Dashboard,
  },
  {
    path: '/dashboard/',
    component: Dashboard,
  },
  // Automatización de pedidos.
  {
    path: '/orders/',
    component: Orders,
  },
  {
    path: '/createOrders/',
    component: CreateOrders,
  },
  {
    path: '/sale-accounts/',
    component: SaleAccounts,
  },
  {
    path: '/create-sale-accounts/',
    component: CreateSaleAccounts,
  },
  {
    path: '/newUserRegister/',
    component: NewUserRegister,
  },
  {
    path: '/consumption/',
    component: Consumption,
  },
  {
    path: '/consumption-sale-accounts/',
    component: ConsumptionSaleAccounts,
  },
  {
    path: '/users/',
    component: Users,
  },
  {
    path: '/createUser/',
    component: CreateUser,
  },
  {
    path: '/editUser/',
    component: EditUser,
  },
  {
    path: '/viewUser/',
    component: ViewUser,
  },
  {
    path: '/companies/',
    component: Companies,
  },
  {
    path: '/createcompany/',
    component: CreateCompany,
  },
  {
    path: '/editcompany/',
    component: EditCompany,
  },
  // userdata
  // No found
  {
    path: '(.*)',
    component: NotFoundPage,
  },
];

export default routes;
