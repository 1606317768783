var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content-devx"},[_c('DxForm',{attrs:{"id":"itemSearcher-form","show-colon-after-label":false,"col-count":3}},[_c('DxGroupItem',[_c('DxSimpleItem',{attrs:{"editor-type":"dxSelectBox","editor-options":{
          items: _vm.companies,
          displayExpr:'value',
          valueExpr:'id',
          placeholder: _vm.$t('Compañia...'),
        }}})],1),_vm._v(" "),_c('DxGroupItem',[_c('DxSimpleItem',{attrs:{"editor-type":"dxDateBox","editor-options":{
          displayFormat: 'MM/yyyy',
          placeholder: _vm.$t('Fecha inicio...'),
          onValueChanged: _vm.initDateSelected
        }}})],1),_vm._v(" "),_c('DxGroupItem',[_c('DxSimpleItem',{attrs:{"editor-type":"dxDateBox","editor-options":{
          displayFormat: 'MM/yyyy',
          placeholder: _vm.$t('Fecha fin...'),
          onValueChanged: _vm.endDateSelected
        }}})],1)],1),_vm._v(" "),_c('f7-row',[_c('f7-col',{attrs:{"width":"70"}}),_vm._v(" "),_c('f7-col',{attrs:{"width":"15"}},[_c('f7-button',{staticClass:" dx-btn-search margin-right",attrs:{"raised":"","styling-mode":"contained"},on:{"click":_vm.clean}},[_vm._v("\n        "+_vm._s(_vm.$t('LIMPIAR'))+"\n      ")])],1),_vm._v(" "),_c('f7-col',{staticStyle:{"margin-right":"10px"},attrs:{"width":"15"}},[_c('f7-button',{staticClass:"dx-btn-search margin-right",attrs:{"raised":"","styling-mode":"contained"},on:{"click":_vm.search}},[_vm._v("\n        "+_vm._s(_vm.$t('BUSCAR'))+"\n      ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }