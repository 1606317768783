<template>
  <f7-navbar :sliding="false">
    <f7-link
      icon-ios="f7:menu"
      icon-aurora="f7:menu"
      icon-md="material:menu"
      panel-toggle="left"
      class="icon-hamburguer"
    />
    <button
      v-if="pathBack != ''"
      class="button-back"
      @click="backTo"
    >
      <img
        class="ic-back"
      >
    </button>
    <f7-nav-title
      class="title-head"
    >
      {{ textUpperCase }}
    </f7-nav-title>
    <f7-nav-right class="nav-right">
      <f7-chip
        class="margin-right"
      >
        {{ user.contact.name }} {{ user.contact.surname }}
      </f7-chip>
      <f7-link popover-open=".navbar-popover-menu">
        <f7-icon
          slot="media"
          class="ic-worker"
        />
      </f7-link>
      <f7-popover class="navbar-popover-menu navbar__popover">
        <f7-list>
          <f7-list-item
            link="/newUserRegister/"
            transition="f7-fade"
            popover-close
            :title="$t('profile')"
          >
            <f7-icon
              slot="media"
              class="color-primary"
              material="person"
            />
          </f7-list-item>
          <f7-list-item
            link="#"
            popover-close
            :title="$t('log_out')"
            @click="logout"
          >
            <f7-icon
              slot="media"
              class="color-primary"
              material="exit_to_app"
            />
          </f7-list-item>
        </f7-list>
      </f7-popover>
    </f7-nav-right>
  </f7-navbar>
</template>

<script>
import {
  mapState, mapActions,
} from 'vuex';
import ExtraElement from '../extraElement.vue';
import ExportExcel from '../ExportExcel.vue';

export default {
  components: {
    ExtraElement,
    ExportExcel,
  },
  props: {
    elements: { type: Array, default: () => [] },
    list: { type: Array, default: () => null },
    typeExport: { type: String, default: '' },
    pathBack: { type: String, default: '' },
    text: { type: String, default: '' },
  },
  data() {
    return {
      textUpperCase: '',
    };
  },
  computed: {
    ...mapState('authentication', ['user']),
  },
  beforeMount() {
    this.textUpperCase = this.text.toUpperCase();
  },
  methods: {
    backTo() {
      this.$f7.views.main.router.navigate(this.pathBack, { reloadCurrent: true });
    },
    ...mapActions('authentication', ['logout', 'setShowLeftPanel']),
  },
};
</script>
<style lang="scss" scoped>
@import "../navbar/icon-workers.scss";
@import '../navbar/Navbar.styles.scss';
</style>
