<template>
  <f7-page>
    <navbar :text="`${$t('LeftPanel_subtitle_user')}`" />
    <div
      v-if="loaded"
    >
      <UserForm
        v-if="loaded"
        :current-user="currentUser"
      />
      <div
        v-if="loaded"
        class="content-action-botton"
      >
        <div
          style="justify-content: flex-start; float: left;"
          class="dx-item dx-toolbar-item dx-toolbar-button"
          @click="goToUsers()"
        >
          <div
            class="dx-item-content dx-toolbar-item-content"
          >
            <div
              class="dx-button dx-button-normal dx-button-mode-contained
              dx-widget add-task dx-button-has-icon dx-button-has-text"
              style=" background: #ffffff;
              border-color: var(--adp-gray);
              color: #838383;"
              aria-label="FICHERO"
              tabindex="0"
              role="button"
            >
              <div
                class="dx-button-content"
                style="
                  display: flex; align-items: center; justify-content:
                  center; height: 34px; min-width: 100px; overflow: hidden;
                  position: relative; margin-right: 6px !important; margin-left: 6px !important;
                  border-radius: 3px !important;
                 "
              >
                <span class="dx-button-text">CANCELAR</span>
              </div>
            </div>
          </div>
        </div>

        <div
          style="justify-content: flex-start; float: left;"
          class="dx-item dx-toolbar-item dx-toolbar-button"
          @click="saveNewUser()"
        >
          <div class="dx-item-content dx-toolbar-item-content">
            <div
              class="dx-button dx-button-normal dx-button-mode-contained
          dx-widget add-task dx-button-has-icon dx-button-has-text"
              aria-label="FICHERO"
              tabindex="0"
              role="button"
            >
              <div
                class="dx-button-content"
                style="display: flex; align-items: center;
                justify-content: center; height: 34px; min-width: 100px;
                overflow: hidden; position: relative; margin-right: 6px !important;
                margin-left: 6px !important; border-radius: 3px !important;"
              >
                <span class="dx-button-text">GUARDAR</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </f7-page>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import navbar from '../../../components/navbar/NavBar.vue';
import UserForm from '../../../components/users/userForm/index.vue';
import Api from '../../../services/Api';

export default {
  name: 'CreateUser',
  components: {
    navbar,
    UserForm,
  },
  data() {
    return {
      loaded: false,
    };
  },
  computed: {
    ...mapState('UserData', ['usersErp', 'currentUser', 'companiesErp',
      'customersAvailables', 'providersAvailables', 'isCompanyOcph']),
  },
  async beforeMount() {
    try {
      this.$f7.preloader.show();
    } catch (error) {
      console.error(error);
      this.$f7.dialog.alert(this.$t(`${error}`));
    } finally {
      this.loaded = true;
      this.$f7.preloader.hide();
    }
  },
  methods: {
    goToUsers() {
      this.setCurrentUser({});
      this.$f7.views.main.router.navigate('/users/', { reloadCurrent: true });
    },
    async saveNewUser() {
      if (this.isValidNewUserForm()) {
        this.$f7.preloader.show();
        try {
          const postNewUser = {};
          postNewUser.name = this.currentUser.name;
          postNewUser.surname = this.currentUser.surname;
          postNewUser.email = this.currentUser.email;
          postNewUser.admin = this.currentUser.admin;
          postNewUser.staff = this.currentUser.staff;
          postNewUser.status = this.currentUser.status;
          // postNewUser.erp_username = this.currentUser.username;
          // postNewUser.company_id = this.currentUser.company_id;
          postNewUser.password = this.currentUser.password;
          // postNewUser.ocph = this.currentUser.isUserOcph;
          // postNewUser.erp_provider_id = this.currentUser.companyProvider;
          // postNewUser.erp_customer_id = this.currentUser.companyCustomer;
          await Api.createNewUser(postNewUser, this.currentUser.id);
        } catch (error) {
          console.error(error);
          this.$f7.dialog.alert(this.$t(`${error}`));
        } finally {
          this.$f7.views.main.router.navigate('/users/', { reloadCurrent: true });
          this.currentUser = '';
          await this.fetchUsers();
          this.$f7.preloader.hide();
          this.$f7.dialog.alert(this.$t('Configuration.saved_successfully'));
          this.onResetForm();
        }
      }
    },
    isValidNewUserForm() {
      let isValidForm = true;

      if (typeof this.currentUser.name === 'undefined'
        || this.currentUser.name === '') {
        this.$f7.dialog.alert('hola 1');
        isValidForm = false;
      } else if (this.currentUser.surname === ''
        || typeof this.currentUser.surname === 'undefined') {
        this.$f7.dialog.alert('hola 2');
        isValidForm = false;
      } else if (this.currentUser.email === ''
        || typeof this.currentUser.email === 'undefined') {
        this.$f7.dialog.alert('hola 3');
        isValidForm = false;
      } else if (this.currentUser.password === ''
        || typeof this.currentUser.password === 'undefined') {
        this.$f7.dialog.alert('hola 4');
        isValidForm = false;
      } else if (this.currentUser.confirmPassword === ''
        || typeof this.currentUser.confirmPassword === 'undefined') {
        this.$f7.dialog.alert('hola 5');
        isValidForm = false;
      } else if (this.currentUser.password !== this.currentUser.confirmPassword) {
        this.$f7.dialog.alert('hola 6');
        isValidForm = false;
      }

      return isValidForm;
    },
    onResetForm() {
      this.resetCurrentUser();
      this.resetCurrentProviderCustomer();
    },
    ...mapActions('UserData', ['setCurrentUser', 'fetchUsers',
      'resetCurrentUser', 'resetCurrentProviderCustomer']),

  },
};
</script>
<style lang="scss" scoped>
</style>
