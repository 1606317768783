// import i18next from 'i18next';
import Api from '../../services/Api';
import Helpers from '../../js/helpers';

export default {
  namespaced: true,
  state: {
    saleAccountSelected: [],
    SaleAccountUpload: {},
    filterdate: '',
    newSaleAccount: {},
    isEdit: false,
    saleAccounts: [],
    listStatus: ['pending', 'cancel', 'acepted'],
    isUploaded: false,
  },
  actions: {
    setClient(context, value) {
      context.commit('setClient', value);
    },
    setDelivery(context, value) {
      context.commit('setDelivery', value);
    },
    setSaleAccountSelected(context, value) {
      context.commit('setSaleAccountSelected', value);
    },
    setIssued(context, value) {
      context.commit('setIssued', value);
    },
    setSaleAccount(context, value) {
      context.commit('setSaleAccount', value);
    },
    setIsEdit(context, value) {
      context.commit('setIsEdit', value);
    },
    setAttachment(context, value) {
      context.commit('setAttachment', value);
    },
    setNameAttachment(context, value) {
      context.commit('setNameAttachment', value);
    },
    setDirection(context, value) {
      context.commit('setDirection', value);
    },
    setListStatus(context, value) {
      context.commit('setListStatus', value);
    },
    setIsUploaded(context, value) {
      context.commit('setIsUploaded', value);
    },
    async deleteSaleAccount(context, idSaleAccount) {
      try {
        await Api.deleteSaleAccount(idSaleAccount);
      } catch (error) {
        // Error controlado en el componente.
      }
    },
    async fetchAllSaleAccounts({
      commit,
      state,
    }, date) {
      try {
        const xhr = await Api.fetchSaleAccounts(date.init, date.end, state.filterdate);
        if ((xhr.status < 200 && xhr.status >= 300) || xhr.response === '') {
          commit('setSaleAccounts', []);
        } else {
          commit('setSaleAccounts', JSON.parse(xhr.response).data);
        }
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
    async getSaleAccount({ commit }, idSaleAccount) {
      try {
        const xhr = await Api.getSaleAccount(idSaleAccount);
        const dataSaleAccounts = JSON.parse(xhr.response);
        commit('fillViewSaleAccount', dataSaleAccounts);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
    async updateSaleAccount({ commit }, data) {
      try {
        await Api.updateSaleAccount(data.id, data);
        commit('resetNewSaleAccount');
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
    async uploadSaleAccount({ commit }, data) {
      try {
        const xhr = await Api.uploadSaleAccount(data);
        const dataUploaded = JSON.parse(xhr.response);
        commit('fillAfterUpload', dataUploaded);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
    async saleAccountsProcess({ state }, data) {
      try {
        await Api.saleAccountsProcess(state.saleAccountSelected, data);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
    async postSaleAccount({ state }) {
      try {
        await Api.postSaleAccount(state.newSaleAccount);
        return Promise.resolve();
      } catch (error) {
        return Promise.reject(Helpers.getError(error));
      }
    },
    resetNewSaleAccount(context, value) {
      context.commit('resetNewSaleAccount', value);
    },
  },
  mutations: {
    setClient(state, value) {
      state.newSaleAccount.customer = value;
    },
    setDelivery(state, value) {
      state.newSaleAccount.date_deliver = value;
    },
    setIssued(state, value) {
      state.newSaleAccount.date_emmission = value;
    },
    setSaleAccount(state, value) {
      state.newSaleAccount.saleAccount = value;
    },
    setIsEdit(state, value) {
      state.isEdit = value;
    },
    setSaleAccounts(state, value) {
      state.saleAccounts = value;
      state.filterdate = '';
    },
    fillViewSaleAccount(state, value) {
      state.newSaleAccount = value;
    },
    setAttachment(state, value) {
      state.newSaleAccount.file = value;
    },
    setNameAttachment(state, value) {
      state.newSaleAccount.filename = value;
    },
    resetNewSaleAccount(state) {
      state.newSaleAccount = {};
    },
    fillAfterUpload(state, value) {
      state.filterdate = value[0].date_emmission;
    },
    setDirection(state, value) {
      state.newSaleAccount.destiny = value;
    },
    setListStatus(state, value) {
      state.newSaleAccount.status = value;
    },
    setIsUploaded(state, value) {
      state.isUploaded = value;
    },
    setSaleAccountSelected(state, value) {
      state.saleAccountSelected = value;
    },

  },
};
