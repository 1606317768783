<template>
  <div
    class="content-devx"
  >
    <DxDataGrid
      id="unitConverter-datagrid"
      class="test"
      :allow-column-reordering="true"
      :data-source="companiesAvailabilities"
      :show-borders="true"
      :show-column-lines="true"
      :show-row-lines="true"
      :row-alternation-enabled="true"
      :column-auto-width="true"
      @toolbar-preparing="onToolbarPreparing($event)"
    >
      <DxStateStoring
        :enabled="true"
        type="localStorage"
        storage-key="companiesAvailabilities"
      />
      <DxEditing />
      <DxExport
        :enabled="true"
      />
      <DxPaging :page-size="10" />
      <DxPager
        :show-page-size-selector="true"
        :show-info="true"
      />
      <DxSearchPanel
        :visible="true"
        :width="200"
        :highlight-case-sensitive="true"
        :placeholder="$t('Search_placeholder')"
      />

      <DxColumn
        data-field="name"
        name="name"
        :caption="$t('companies.company_name')"
        :placeholder="$t('Search_placeholder')"
        :allow-editing="false"
        alignment="center"
        data-type="string"
      />
      <DxColumn
        data-field="credential.username"
        name="username"
        :caption="$t('companies.username')"
        :placeholder="$t('Search_placeholder')"
        :allow-editing="false"
        alignment="center"
        data-type="string"
      />
      <DxColumn
        data-field="credential.host"
        name="host"
        :caption="$t('companies.host')"
        :placeholder="$t('Search_placeholder')"
        :allow-editing="false"
        alignment="center"
        data-type="string"
      />

      <DxColumn
        :caption="$t('Opciones')"
        type="buttons"
        :fixed="true"
        fixed-position="right"
      >
        <DxButton
          name="other"
          icon="edit"
          :on-click="editCompany"
        />
        <DxButton
          name="remove"
          icon="trash"
          :on-click="removeCompany"
        />
      </DxColumn>
      <template #grid-cell-template="{ data }">
        <div>
          <img
            style="width:100px"
            :src="data.value"
          >
        </div>
      </template>
    </DxDataGrid>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import {
  DxDataGrid, DxExport, DxButton, DxColumn,
  DxStateStoring, DxEditing, DxPaging, DxPager, DxSearchPanel,
} from 'devextreme-vue/data-grid';
import { exportDataGrid } from 'devextreme/excel_exporter';
import ExcelJS from 'exceljs';
import saveAs from 'file-saver';
import Api from '../../../services/Api';

export default {
  name: 'CompaniesAvailabilities',
  components: {
    DxDataGrid,
    DxColumn,
    DxPaging,
    DxSearchPanel,
    DxExport,
    DxStateStoring,
    DxPager,
    DxEditing,
    DxButton,

  },

  props: {
    companiesAvailabilities: {
      type: Array,
      default: () => [],
    },

  },
  data() {
    return {
      pageSizes: [5, 10, 20],
      someError: false,
    };
  },
  methods: {
    // EXPORT EXCEL
    onExporting(e) {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('ReportProviders');
      exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
      }).then(() => {
        workbook.xlsx.writeBuffer()
          .then((buffer) => {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'ReportProviders.xlsx');
          });
      });
      e.cancel = true;
    },
    editCompany(e) {
      this.getCompanyId(e.row.data.id);
      this.$f7.views.main.router.navigate('/editcompany/', { reloadCurrent: true });
    },
    removeCompany(e) {
      // this.updateCurrentUserProperty({ property: 'id', newValue: e.row.data.id });
      this.$f7.dialog.confirm(this.$t('Configuration.sure_remove_user'), () => {
        this.removeCompanyFromApi(e.row.data.id);
      });
    },
    async removeCompanyFromApi(companyDeleteId) {
      this.$f7.preloader.show();
      try {
        await Api.deleteCompany(companyDeleteId);
      } catch (error) {
        console.error(error);
        this.someError = true;
        this.$f7.dialog.alert(this.$t(`${error}`));
      } finally {
        this.loaded = true;
        this.$f7.preloader.hide();
        if (!this.someError) {
          this.$f7.dialog.alert('Great!');
          this.$f7.views.main.router.navigate('/companies/', { reloadCurrent: true });
        }
      }
    },
    onToolbarPreparing(e) {
      e.toolbarOptions.items.unshift({
        location: 'after',
        widget: 'dxButton',
        options: {
          icon: 'add',
          elementAttr: {
            class: 'add-task',
          },
          onClick: () => {
            this.$f7.views.main.router.navigate('/createcompany/', { reloadCurrent: true });
          },
        },
      });
    },
    ...mapActions('Companies', ['setSelectedCompany', 'getCompanyId']),

  },
};
</script>
<style lang="scss" scoped>
@import './TableCompanies.styles.scss';
</style>
