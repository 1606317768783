import {
  f7,
} from 'framework7-vue';
import moment from 'moment';
import Request from './Request';
import EventBus from '../js/event-bus';

let username = '';
let token = '';

// const baseUrl = 'http://localhost:8000'; // Endpoint estable desarrollo
// const baseUrl = 'https://adp.development.hispatecanalytics.com'; // Endpoint estable desarrollo
// const baseUrl = 'https://api.adp.pre.hispatecanalytics.com'; // Endpoint preproducción
const baseUrl = 'https://api.adp.hispatecanalytics.com'; // Endpoint producción

EventBus.$on('newLoggedUser', (payload) => {
  const data = JSON.parse(payload);
  username = data.username;
  token = data.token;
});

export default {

  errors: {
    default: {
      name: 'error',
      message: 'error',
      callback() {},
    },

    E002: {
      name: 'invalid_token',
      message: 'Su sesión ha expirado',

      callback() {
        if (!username) return;
        username = null;
        EventBus.$emit('invalidTokenDetected');
        f7.dialog.alert(this.message);
      },
    },
  },
  getToken() {
    return token;
  },
  getHeaderAuth() {
    return localStorage.getItem('headerAuth');
  },
  getBaseUrl() {
    return baseUrl;
  },

  async fetchUserPermissions() {
    const url = `${baseUrl}/list-user-permissions/`;
    try {
      const response = await Request.async(url, {}, 'GET', false, 'application/json', this.getHeaderAuth());
      const permissions = JSON.parse(response.response);

      EventBus.$emit('setUserPermissions', permissions);
    } catch (error) {
      console.error('Error fetching user permissions:', error);
    }
  },

  // Login
  async login(credentials) {
    const url = `${baseUrl}/user/authorization`;
    const payload = JSON.stringify({
      username: credentials.username,
      password: credentials.password,
    });
    const result = await Request.async(url, payload, 'POST', false, 'application/json');
    await this.handleLoginResult(result);
  },

  async handleLoginResult(result) {
    if (result.response !== '') {
      EventBus.$emit('newLoggedUser', result.response);
      localStorage.setItem('headerAuth', `Bearer ${token}`);
      await this.fetchUserPermissions();
    }
  },
  //  Auxiliary methods
  async request(url, payload, verb, processData, contentType) {
    const response = await Request.async(url, payload, verb, processData, contentType);
    return response;
  },

  getException(errorcode) {
    return errorcode in this.errors ? this.errors[errorcode] : this.errors.default;
  },

  // ADP
  async fetchOrders(init = '', end = '', parseDate) {
    let url = `${baseUrl}/orders`;
    const queryParams = new URLSearchParams();
    if (parseDate !== '') {
      queryParams.set('init', parseDate);
      queryParams.set('end', parseDate);
    } else {
      if (init !== '') {
        queryParams.set('init', init);
      }
      if (end !== '') {
        queryParams.set('end', end);
      }
    }

    url += `?${queryParams.toString()}`;
    const payload = null;
    return Request.async(url, payload, 'GET', false, 'application/json', this.getHeaderAuth());
  },
  AzureAuthorization() {
    const url = `${baseUrl}/azure/authorization`;
    const payload = {
      data: null,
    };
    return Request.async(url, payload, 'GET', false, 'application/json');
  },
  AzureGetToken(code) {
    const url = `${baseUrl}/azure/authorization`;
    const payload = {
      data: JSON.stringify({
        code,
      }),
    };
    return Request.async(url, payload, 'POST', false, 'application/json')
      .then(this.handleLoginResult);
  },
  getOrder(idOrder) {
    const url = `${baseUrl}/orders/${idOrder}`;
    const payload = null;
    return Request.async(url, payload, 'GET', false, 'application/json', this.getHeaderAuth());
  },
  updateOrder(idOrder, data) {
    const url = `${baseUrl}/orders/${idOrder}`;
    const payload = JSON.stringify(data);
    return Request.async(url, payload, 'PUT', false, 'application/json', this.getHeaderAuth());
  },
  deleteOrder(idOrder) {
    const url = `${baseUrl}/orders/${idOrder}`;
    const payload = null;
    return Request.async(url, payload, 'DELETE', false, 'application/json', this.getHeaderAuth());
  },
  uploadOrder(data) {
    const url = `${baseUrl}/orders/parse`;
    const payload = JSON.stringify(data);
    return Request.async(url, payload, 'POST', false, 'application/json', this.getHeaderAuth());
  },
  ordersProcess(ordersId, data) {
    const url = `${baseUrl}/orders/batch`;
    const payload = JSON.stringify({
      id: ordersId,
      status: data,
    });
    return Request.async(url, payload, 'PUT', false, 'application/json', this.getHeaderAuth());
  },

  postOrder(data) {
    const url = `${baseUrl}/orders`;
    const payload = JSON.stringify(data);
    return Request.async(url, payload, 'POST', false, 'application/json', this.getHeaderAuth());
  },

  fetchProfile() {
    const url = `${baseUrl}/profile`;
    const payload = null;
    return Request.async(url, payload, 'GET', false, 'application/json', this.getHeaderAuth());
  },
  updateProfile(data) {
    const url = `${baseUrl}/profile`;
    const payload = JSON.stringify(data);
    return Request.async(url, payload, 'PUT', false, 'application/json', this.getHeaderAuth());
  },
  checkInitEndDate(actualFilter) {
    let init = '';
    let end = '';
    if (typeof actualFilter !== 'undefined' && actualFilter.init !== 'Invalid date' && actualFilter.end !== 'Invalid date') {
      init = actualFilter.init;
      end = actualFilter.end;
    }
    if (init === '') {
      init = moment().subtract(7, 'days').format('YYYY-MM-DD');
      end = moment().format('YYYY-MM-DD');
    }
    return { init, end };
  },
  fetchCompanies() {
    let url = `${baseUrl}/companies/enumerate`;
    const queryParams = new URLSearchParams();
    url += `?${queryParams.toString()}`;
    const payload = null;
    return Request.async(url, payload, 'GET', false, 'application/json', this.getHeaderAuth());
  },
  fetchAllCompanies() {
    let url = `${baseUrl}/companies`;
    const queryParams = new URLSearchParams();
    url += `?${queryParams.toString()}`;
    const payload = null;
    return Request.async(url, payload, 'GET', false, 'application/json', this.getHeaderAuth());
  },
  fetchConsumption(init, end) {
    let url = `${baseUrl}/consume`;
    const queryParams = new URLSearchParams();
    if (init !== '') {
      queryParams.set('init', init);
    }
    if (end !== '') {
      queryParams.set('end', end);
    }

    url += `?${queryParams.toString()}`;
    const payload = null;
    return Request.async(url, payload, 'GET', false, 'application/json', this.getHeaderAuth());
  },
  fetchConsumptionSaleAccount(init, end) {
    let url = `${baseUrl}/consume-sale-account`;
    const queryParams = new URLSearchParams();
    if (init && init !== '') {
      queryParams.set('init', init);
    }
    if (end && end !== '') {
      queryParams.set('end', end);
    }

    url += `?${queryParams.toString()}`;
    const payload = null;
    return Request.async(url, payload, 'GET', false, 'application/json', this.getHeaderAuth());
  },
  async fetchUsers() {
    const url = `${baseUrl}/contacts`;
    const payload = null;
    return Request.async(url, payload, 'GET', false, 'application/json', this.getHeaderAuth());
  },
  async getUser(userId) {
    const url = `${baseUrl}/contacts/${userId}`;
    const payload = null;
    return Request.async(url, payload, 'GET', false, 'application/json', this.getHeaderAuth());
  },

  async createUser(user) {
    const url = `${baseUrl}/contacts`;
    const payload = JSON.stringify(user);
    return Request.async(url, payload, 'POST', false, 'application/json', this.getHeaderAuth());
  },
  async createNewUser(newUser) {
    const url = `${baseUrl}/contacts`;
    const payload = JSON.stringify(newUser);
    return Request.async(url, payload, 'POST', false, 'application/json', this.getHeaderAuth());
  },
  async editUser(user, userId) {
    const url = `${baseUrl}/contacts/${userId}`;
    const payload = JSON.stringify(user);
    return Request.async(url, payload, 'PUT', false, 'application/json', this.getHeaderAuth());
  },
  async deleteUser(userId) {
    const url = `${baseUrl}/contacts/${userId}`;
    const payload = null;
    return Request.async(url, payload, 'DELETE', false, 'application/json', this.getHeaderAuth());
  },

  // companies
  createCompany(company) {
    const url = `${baseUrl}/companies`;
    const payload = JSON.stringify(company);
    return Request.async(url, payload, 'POST', false, 'application/json', this.getHeaderAuth());
  },
  async deleteCompany(companyId) {
    const url = `${baseUrl}/companies/${companyId}`;
    const payload = null;
    return Request.async(url, payload, 'DELETE', false, 'application/json', this.getHeaderAuth());
  },
  async getCompany(CompanyId) {
    const url = `${baseUrl}/companies/${CompanyId}`;
    const payload = null;
    return Request.async(url, payload, 'GET', false, 'application/json', this.getHeaderAuth());
  },
  async editCompany(companyId, company) {
    const url = `${baseUrl}/companies/${companyId}`;
    const payload = JSON.stringify(company);
    return Request.async(url, payload, 'PUT', false, 'application/json', this.getHeaderAuth());
  },
  // Sale Account
  async fetchSaleAccounts(init = '', end = '', parseDate) {
    let url = `${baseUrl}/sale_accounts`;
    const queryParams = new URLSearchParams();
    if (parseDate !== '') {
      queryParams.set('init', parseDate);
      queryParams.set('end', parseDate);
    } else {
      if (init !== '') {
        queryParams.set('init', init);
      }
      if (end !== '') {
        queryParams.set('end', end);
      }
    }

    url += `?${queryParams.toString()}`;
    const payload = null;
    return Request.async(url, payload, 'GET', false, 'application/json', this.getHeaderAuth());
  },
  getSaleAccount(idSaleAccount) {
    const url = `${baseUrl}/sale_accounts/${idSaleAccount}`;
    const payload = null;
    return Request.async(url, payload, 'GET', false, 'application/json', this.getHeaderAuth());
  },
  updateSaleAccount(idSaleAccount, data) {
    const url = `${baseUrl}/sale_accounts/${idSaleAccount}`;
    const payload = JSON.stringify(data);
    return Request.async(url, payload, 'PUT', false, 'application/json', this.getHeaderAuth());
  },
  deleteSaleAccount(idSaleAccount) {
    const url = `${baseUrl}/sale_accounts/${idSaleAccount}`;
    const payload = null;
    return Request.async(url, payload, 'DELETE', false, 'application/json', this.getHeaderAuth());
  },
  uploadSaleAccount(data) {
    const url = `${baseUrl}/sale_accounts/parse`;
    const payload = JSON.stringify(data);
    return Request.async(url, payload, 'POST', false, 'application/json', this.getHeaderAuth());
  },
  saleAccountsProcess(saleAccountsId, data) {
    const url = `${baseUrl}/sale_accounts/batch`;
    const payload = JSON.stringify({
      id: saleAccountsId,
      status: data,
    });
    return Request.async(url, payload, 'PUT', false, 'application/json', this.getHeaderAuth());
  },

  postSaleAccount(data) {
    const url = `${baseUrl}/sale_accounts`;
    const payload = JSON.stringify(data);
    return Request.async(url, payload, 'POST', false, 'application/json', this.getHeaderAuth());
  },
};
