var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content-devx"},[_c('DxForm',{attrs:{"id":"userForm"}},[_c('DxGroupItem',{attrs:{"css-class":"content-combo","col-count":1}},[_c('DxGroupItem',[_c('DxSimpleItem',{attrs:{"name":"name","data-field":"name","editor-options":{
            onValueChanged: _vm.onChangeName,
            placeholder: _vm.$t('Nombre ...'),
          }}},[_c('DxLabel',{attrs:{"text":_vm.$t('NOMBRE')}}),_vm._v(" "),_c('DxRequiredRule',{attrs:{"message":_vm.$t('Field_Required')}})],1),_vm._v(" "),(_vm.user.contact.staff)?_c('DxSimpleItem',{attrs:{"name":"company_name","data-field":"company_name","editor-type":"dxSelectBox","editor-options":{
            items: _vm.companies,
            displayExpr: 'value',
            valueExpr: 'id',
            onValueChanged: _vm.onChangeCompany,
          }}},[_c('DxLabel',{attrs:{"text":_vm.$t('Compañia')}}),_vm._v(" "),_c('DxRequiredRule',{attrs:{"message":_vm.$t('Field_Required')}})],1):_vm._e(),_vm._v(" "),_c('DxSimpleItem',{attrs:{"name":"surname","data-field":"surname","editor-options":{
            onValueChanged: _vm.onChangeSurname,
            placeholder: _vm.$t('Apellido ...'),
          }}},[_c('DxLabel',{attrs:{"text":_vm.$t('APELLIDO')}}),_vm._v(" "),_c('DxRequiredRule',{attrs:{"message":_vm.$t('Field_Required')}})],1),_vm._v(" "),_c('DxSimpleItem',{attrs:{"name":"email","data-field":"email","editor-options":{
            onValueChanged: _vm.onChangeEmail,
            placeholder: _vm.$t('Email ...'),
          }}},[_c('DxLabel',{attrs:{"text":_vm.$t('EMAIL')}}),_vm._v(" "),_c('DxRequiredRule',{attrs:{"message":_vm.$t('Field_Required')}})],1),_vm._v(" "),_c('DxSimpleItem',{attrs:{"name":"password","data-field":"password","editor-options":{
            onValueChanged: _vm.onChangePassword,
            placeholder: _vm.$t('Indique su contraseña ...'),
          }}},[_c('DxRequiredRule',{attrs:{"message":_vm.$t('Field_Required')}}),_vm._v(" "),_c('DxLabel',{attrs:{"text":_vm.$t('CONTRASEÑA')}})],1),_vm._v(" "),_c('DxSimpleItem',{attrs:{"name":"confirmPassword","data-field":"confirmPassword","editor-options":{
            onValueChanged: _vm.onChangeConfirmPassword,

            placeholder: _vm.$t('Repita su contraseña ...'),

          }}},[_c('DxLabel',{attrs:{"text":_vm.$t('CONFIRMAR')}})],1)],1)],1)],1),_vm._v(" "),_c('div',[_vm._m(0),_vm._v(" "),_c('DxCheckBox',{staticClass:"padding",attrs:{"value":_vm.status,"text":_vm.$t('ACTIVO').toUpperCase()},on:{"value-changed":_vm.OnSelectedMakeActive}})],1),_vm._v(" "),(_vm.user.contact.staff)?_c('div',[_vm._m(1),_vm._v(" "),_c('DxCheckBox',{staticClass:"padding",attrs:{"value":_vm.staff,"text":_vm.$t('STAFF').toUpperCase()},on:{"value-changed":_vm.OnSelectedMakeStaff}})],1):_vm._e(),_vm._v(" "),(_vm.user.contact.admin || _vm.user.contact.staff)?_c('div',[_vm._m(2),_vm._v(" "),_c('DxCheckBox',{staticClass:"padding",attrs:{"value":_vm.admin,"text":_vm.$t('ADMIN').toUpperCase()},on:{"value-changed":_vm.OnSelectedMakeAdmin}})],1):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('label',{staticClass:"margin dx-field-item-label dx-field-item-label-location-left",attrs:{"for":"dx_dx-6598e3e6-1fec-d875-6768-c9bd57af04a5_password"}},[_c('span',{staticClass:"dx-field-item-label-content",staticStyle:{"width":"92px"}},[_c('span',{staticClass:"dx-field-item-label-text"},[_vm._v("Hacer usuario Activo")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('label',{staticClass:"margin dx-field-item-label dx-field-item-label-location-left",attrs:{"for":"dx_dx-6598e3e6-1fec-d875-6768-c9bd57af04a5_password"}},[_c('span',{staticClass:"dx-field-item-label-content",staticStyle:{"width":"92px"}},[_c('span',{staticClass:"dx-field-item-label-text"},[_vm._v("Hacer usuario STAFF :")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('label',{staticClass:"margin dx-field-item-label dx-field-item-label-location-left",attrs:{"for":"dx_dx-6598e3e6-1fec-d875-6768-c9bd57af04a5_password"}},[_c('span',{staticClass:"dx-field-item-label-content",staticStyle:{"width":"92px"}},[_c('span',{staticClass:"dx-field-item-label-text"},[_vm._v("Hacer usuario ADMN:")])])])}]

export { render, staticRenderFns }